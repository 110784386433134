import React, { useState } from "react";
import styled from "styled-components";
import Widget, { Font, Color, WarnLabel } from "./Widget";
import * as Ant from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import * as Survey from "../Contexts/SurveyContext";
import RichTextEditor from "./RichTextEditor";
import QuestionBottomBar from "./QuestionBottomBar";

function TextQuestionEdit(props) {
  const _save = async () => {
    if (props.editorProps.isContentBlank()) {
      Ant.Modal.warning({
        title: "文字不可為空",
        content: "必須有文字",
        okText: "確定",
        icon: <ExclamationCircleFilled color={Color.LightGold} size={20} />,
      });
      return;
    }
  };

  return {
    ...(props.readOnly ? (
      <>
        <Wrapper style={props.style}>
          <Widget.FlexRow style={{ alignItems: "flex-start" }}>
            <div style={{ flex: 1 }}>
              <RichTextEditor readOnly={true} />
            </div>
          </Widget.FlexRow>
        </Wrapper>
      </>
    ) : (
      <>
        <Wrapper style={props.style}>
          <Widget.FlexRow style={{ alignItems: "flex-start" }}>
            <div style={{ flex: 1 }}>
              <RichTextEditor
                editor={props.editorProps.editor}
                placeholder="結束頁文字"
                endingPage={props.endingPage}
                styles={{ minHeight: 235 }}
              />
            </div>
          </Widget.FlexRow>
        </Wrapper>
      </>
    )),
  };
}

const QuestionIdx = styled.div`
  width: 32px;
  height: 32px;
  background-color: #000000a6;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  /* padding-top: 20px; */
  /* padding-right: 20px; */
`;

export default TextQuestionEdit;
