import React, { useState, useEffect } from "react";
import styled from "styled-components";
import * as Icon from "./Icon";
import * as Ant from "antd";
import { FontSize, Color } from "./Widget";
import SurveyPage, { buildPager } from "./SurveyPage";
import SurveyFinish from "./SurveyFinish";
import SurveyWelcome from "./SurveyWelcome";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import * as Survey from "../Contexts/SurveyContext";
const key = "loading";

const configs = {
  topPagesBarHeight: 38,
  bottomPagesBarHeight: 40,
};

function SurveyDetailEditor({ pages, ...props }) {
  // 2022-08-02 ALVIN 原本爲是否問卷頁面，更改爲：問卷、歡迎頁、結束頁三種頁面呈現
  // 1: 歡迎頁; 2: 問卷; 3: 結束頁
  // 預設問卷頁面
  const [pageType, setPageType] = useState(2);

  return (
    <Wrapper>
      {/* 1: 歡迎頁; 2: 問卷; 3: 結束頁 */}
      {/* type = 2 render 問卷設計頁面 */}
      {pageType === 2 ? (
        <>
          <div className="top-pages-bar">
            <div className="container">
              <Ant.Select
                size={"small"}
                defaultValue="0"
                style={{ width: 110 }}
                onSelect={v => {
                  try {
                    // const pageOffset = -32;
                    const ele = document.getElementById(`header-${v}`);
                    // const y =
                    //   ele.getBoundingClientRect().top +
                    //   window.pageYOffset +
                    //   pageOffset;
                    ele.scrollIntoView({
                      behavior: "smooth",
                    });
                  } catch (err) {
                    //
                  }
                }}
              >
                {pages &&
                  pages.map((page, idx, arr) => (
                    <Ant.Select.Option
                      key={`${idx}`}
                      className={`page-option`}
                      onClick={() => 0}
                    >
                      {`第 ${idx + 1} 頁`}
                    </Ant.Select.Option>
                  ))}
              </Ant.Select>
            </div>
          </div>
          <div className="questions-content">
            {pages &&
              pages.map(({ begin, end }, pageIdx) => (
                <div
                  id={`page-idx-${pageIdx}`}
                  key={pageIdx}
                  className="questions-content-wrapper"
                >
                  <SurveyPage
                    pageIdx={pageIdx}
                    begin={begin}
                    end={end}
                    lastPage={pageIdx === pages.length - 1}
                    setUpdater={props.setUpdater}
                    updater={props.updater}
                    {...props}
                  />
                </div>
              ))}
          </div>
        </>
      ) : pageType === 1 ? (
        <div
          className="questions-content-wrapper"
          style={{ marginTop: "36px" }}
        >
          <SurveyWelcome {...props} />
        </div>
      ) : (
        <div
          className="questions-content-wrapper"
          style={{ marginTop: "36px" }}
        >
          <SurveyFinish {...props} />
        </div>
      )}
      {/* type = 1 render 問卷歡迎頁面，反之則為完成頁 */}
      <div className="bottom-pages-bar">
        <div className="buttons-wrapper">
          {/* TODO: update active style when imple ending page */}
          <div
            className={pageType === 1 ? `button active` : `button`}
            onClick={() => setPageType(1)}
          >
            歡迎頁
          </div>
          <div
            className={pageType === 2 ? `button active` : `button`}
            onClick={() => setPageType(2)}
          >
            問卷
          </div>
          <div
            className={pageType === 3 ? `button active` : `button`}
            onClick={() => setPageType(3)}
          >
            結束頁
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

// 問卷編輯: 第一步 設計問卷
function SurveyDetailContentConfig(props) {
  const [draggingItem, setDraggingItem] = useState(null);
  let pages = buildPager(props.survey);
  let pagesLength = pages.length;

  function onDragStart(data) {
    setDraggingItem(data.draggableId);
  }

  async function _onDragEnd(result) {
    setDraggingItem(undefined);
    //console.log("onDragEnd", result);
    if (props.previewMode || props.editingIdx !== null) {
      return;
    }
    // fix 改採用 source index
    const srcIdx = parseInt(result.source?.index);
    const destIdx = parseInt(result.destination?.index);

    if (srcIdx === destIdx) {
      return;
    }

    // 2022-05-27 : 選項拖曳，將更新部分獨立Function，供 MoveModal 呼叫
    // // let the ui refresh first
    // const nextSurveySync = props.survey.reorderQuestion(srcIdx, destIdx);
    // props.setSurvey(nextSurveySync);

    // // actually commit the change through API
    // await Survey.Actions.reorderQuestionById(
    //   props.survey.id,
    //   srcIdx,
    //   destIdx,
    //   props.survey
    // );
    // props.setUpdater(props.updater + 1);
    reorderQuestionById(srcIdx, destIdx);
  }

  /**
   * 選項拖曳後重新排序
   * @param {int} srcIdx 來源index
   * @param {int} destIdx 目標index
   * @returns void
   */
  async function reorderQuestionById(srcIdx, destIdx) {
    // 來源目標一致提早返回
    if (srcIdx === destIdx) {
      return;
    }

    // let the ui refresh first
    const nextSurveySync = props.survey.reorderQuestion(srcIdx, destIdx);
    props.setSurvey(nextSurveySync);

    // actually commit the change through API
    await Survey.Actions.reorderQuestionById(
      props.survey.id,
      srcIdx,
      destIdx,
      props.survey
    );
    props.setUpdater(props.updater + 1);
  }

  useEffect(() => {
    if (props.isCreateNewPage) {
      const main = document.getElementById("mian");
      let mainHeight = main.scrollHeight;
      main.scrollTo({
        top: mainHeight + 120,
        behavior: "smooth",
      });
      props.setIsCreateNewPage(false);
    }
  }, [pagesLength]);

  return (
    <DragDropContext onBeforeCapture={onDragStart} onDragEnd={_onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            <SurveyDetailEditor
              pages={pages}
              reorderQuestionById={reorderQuestionById}
              draggingItem={draggingItem}
              {...props}
            />

            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

const Wrapper = styled.div`
  /* --content-width: calc(100% - 180px); */
  --content-width: 800px;
  width: 100%;
  position: relative;
  padding-bottom: ${configs.bottomPagesBarHeight + 20}px;
  height: 100%;
  & .questions-content {
    width: var(--content-width);
    margin: 0 auto;
    background-color: #ffffff;
    border-radius: 5px;
    margin-top: 12px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
    /* overflow: hidden; */
  }
  & .questions-content-wrapper {
  }

  & .top-pages-bar {
    height: 36px;
    position: sticky;
    top: 0px;
    padding-top: 12px;
    padding-bottom: 0;
    /* background-color: #eff1f7; */
    z-index: 1;

    & > .container {
      width: var(--content-width);
      margin: 0 auto;
      .ant-select {
        border-radius: 2px;
        border: solid 1px rgba(83, 74, 179, 0.85);
        background-color: rgba(255, 255, 255, 0.4);
        .ant-select-selection-item {
          color: rgba(83, 74, 179, 0.85);
        }
        .ant-select-arrow {
          .anticon {
            color: rgba(83, 74, 179, 0.85);
          }
        }
      }
    }
  }

  & .bottom-pages-bar {
    height: ${configs.bottomPagesBarHeight}px;
    position: fixed;
    bottom: 26px;
    width: 100%;
    display: flex;
    margin-left: 16px;
    pointer-events: none;
    z-index: 10;

    & .buttons-wrapper {
      pointer-events: all;
      box-shadow: 0 9px 28px 8px rgba(0, 0, 0, 0.05),
        0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12);
      display: flex;
      & .button {
        padding: 8px 16px;
        display: flex;
        align-items: center;
        cursor: pointer;
        font-size: ${FontSize.head}px;
        color: ${Color.GreyBlack};
        background-color: ${Color.GreyWhite};
      }

      & .button.active {
        color: #534ab3;
        background-color: ${Color.LightBlue_1};
      }
    }
  }
`;

export default SurveyDetailContentConfig;
