import React, { Component } from "react";
import styled from "styled-components";
import ActionCreator from "../ActionCreator";
import Selectors from "../Selectors";
import { connect } from "react-redux";
import * as Icon from "./Icon";
import Widget, { FontSize, Color, Font } from "./Widget";
import QUESTION_TYPE from "../Domain/QuestionType";
import * as Survey from "../Contexts/SurveyContext";
import * as Ant from "antd";
import LookAndFeelConfig from "./LookAndFeelConfig";
import ProgressConfig from "./ProgressConfig";
import clickOnce from "../Utils/ClickOnce";
import * as SvgIcon from "./SvgIcon";

const TABS = [
  { display: "基本設定", key: "setting" },
  { display: "建立題目", key: "create-question" },
  { display: "外觀主題", key: "theme" },
];

const SETTING_ITEMS = [
  {
    display: "頁面編號",
    iconLink: "/images/icons/2_Setting/Page-24px.svg",
    key: 1,
    field: "pageIdxDisplay",
  },
  {
    display: "問題編號",
    iconLink: "/images/icons/2_Setting/TitleNumber-24px.svg",
    key: 2,
    field: "questionIdxDisplay",
  },
  {
    display: "選項編號",
    iconLink: "/images/icons/2_Setting/ChoiceNumber-24px.svg",
    key: 3,
    field: "optionIdxDisplay",
  },
  {
    display: "必填星號",
    iconLink: "/images/icons/2_Setting/MustDo-24px.svg",
    key: 4,
    field: "asteriskDisplay",
  },
  {
    display: "顯示填寫進度",
    iconLink: "/images/icons/2_Setting/ProcessBar-24px.svg",
    key: 5,
    field: "progressDisplay",
    nested: true,
  },
  {
    display: "使用驗證碼",
    iconLink: "/images/icons/2_Setting/Password-24px.svg",
    key: 6,
    field: "validationCode",
  },
  {
    display: "一頁一題模式",
    iconLink: "/images/icons/2_Setting/MultiplePage-24px.svg",
    key: 7,
    field: "oneQuestionPerPage",
  },
  // 增加歡迎頁
  {
    display: "歡迎頁",
    iconLink: "/images/icons/2_Setting/EndPage-24px.svg",
    key: 9,
    field: "welcomeDisplay",
    tips: "因應中華電信個資管理規範，須請用戶勾選個資聲明才可填寫問卷，此歡迎頁無法關閉，但可修改歡迎頁訊息與圖片。",
    lockOn: true,
  },
  {
    display: "結束頁",
    iconLink: "/images/icons/2_Setting/EndPage-24px.svg",
    key: 8,
    field: "endingDisplay",
  },
];
// 問卷左側 Menu
class SurveyDetailSideMenuConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: TABS[0].key,
      surveyConfigs: [],
      nestedConfig: null,
    };
  }

  render() {
    let { activeTab, surveyConfigs, nestedConfig } = this.state;
    let { editingIdx, survey } = this.props;

    // if (
    //   this.state.activeTab === "create-question" &&
    //   this.props.updater === 0
    // ) {
    //   this.state.activeTab = TABS[0].key;
    // }
    // note: only enable sidemenu.config function when editingIdx === null AND survey is not audit
    const enable =
      editingIdx === null && survey && !survey.audit && this.props.canEdit;
    return (
      <Wrapper disable={!enable}>
        <div className="tabs">
          {TABS.map((tab, idx) => (
            <div
              key={idx}
              className={`tab ${tab.key === activeTab ? "active" : ""}`}
              onClick={() => {
                // this.props.setUpdater(this.props.updater + 1);
                this.setState({ activeTab: tab.key });
              }}
            >
              {tab.display}
            </div>
          ))}
        </div>
        {/* 依據 active tab 切換頁面 */}
        <div className="content scrollbar-small">
          {this.props.survey &&
            (() => {
              switch (activeTab) {
                case TABS[0].key:
                  if (nestedConfig === "progress") {
                    return (
                      <ProgressConfig
                        survey={survey}
                        setSurvey={this.props.setSurvey}
                        unModelSurvey={this.props.unModelSurvey}
                        setUpdater={this.props.setUpdater}
                        updater={this.props.updater}
                        goBack={() => this.setState({ nestedConfig: null })}
                      />
                    );
                  }
                  return (
                    <div className="content-setting">
                      {SETTING_ITEMS.map((item, idx) => {
                        return (
                          <div key={idx} className="setting-item">
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {/* <Icon.RadioButtonChecked
                                size={FontSize.title}
                                color={"#585858"}
                              /> */}
                              <div className="icon">
                                <div
                                  className="img-svg"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    backgroundColor: `rgba(0, 0, 0, 0.65)`,
                                    // backgroundImage: `url(${item.iconLink})`,
                                    mask: `url(${item.iconLink}) no-repeat center`,
                                    WebkitMask: `url(${item.iconLink}) no-repeat center`,
                                    maskSize: "contain",
                                    WebkitMaskSize: "contain",
                                  }}
                                />
                              </div>
                              <div className="text">{item.display}</div>
                              {item.tips !== undefined && (
                                <Ant.Tooltip title="因應中華電信個資管理規範，須請用戶勾選個資聲明才可填寫問卷，此歡迎頁無法關閉，但可修改歡迎頁訊息與圖片。">
                                  <SvgIcon.Help
                                    size={20}
                                    color="#ffc53d"
                                    style={{ marginLeft: 8 }}
                                  />
                                </Ant.Tooltip>
                              )}
                              
                            </div>
                            {item.nested ? (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Font.Small
                                  style={
                                    survey[item.field]
                                      ? { color: `#534AB3` }
                                      : { color: Color.GreyBlack_45 }
                                  }
                                >
                                  {survey[item.field] ? "開" : "關"}
                                </Font.Small>
                                <Icon.KeyboardArrowRight
                                  size={FontSize.largeTitle}
                                  color={
                                    survey[item.field]
                                      ? `#534AB3`
                                      : Color.GreyBlack_45
                                  }
                                  onClick={() =>
                                    this.setState({ nestedConfig: "progress" })
                                  }
                                />
                              </div>
                            ) : (
                              <Widget.DetailedSwitch
                                size={"small"}
                                disabled={item.lockOn !== undefined}
                                wrapperStyle={{ marginRight: 5 }}
                                checked={item.lockOn != undefined ? true : this.props.survey[item.field]}
                                onChange={clickOnce(async checked => {
                                  const hide = Ant.message.loading("更新中", 0);
                                  try {
                                    this.props.setSurvey(
                                      await Survey.Actions.updatePropertyById(
                                        this.props.survey.id,
                                        { [item.field]: checked },
                                        this.props.unModelSurvey
                                      )
                                    );
                                  } catch (ex) {}
                                  hide();
                                  // await Survey.Actions.updatePropertyById(
                                  //   this.props.survey.id,
                                  //   { [item.field]: checked }
                                  // );
                                  // this.props.setUpdater(this.props.updater + 1);
                                })}
                              />
                            )}
                          </div>
                        );
                      })}
                    </div>
                  );
                case TABS[1].key:
                  return (
                    <div className="content-question">
                      {Object.keys(QUESTION_TYPE).map((typeKey, idx) => (
                        <div
                          key={idx}
                          className="question-type"
                          onClick={clickOnce(async () => {
                            if (!enable) {
                              return;
                            }

                            const hide = Ant.message.loading("創建中", 0);
                            try {
                              const totalQuestions = this.props.survey.questions
                                .length;
                              await Survey.Actions.createQuestionById(
                                this.props.survey.id,
                                typeKey,
                                this.props.survey.lastPageNo()
                              );
                              this.props.setUpdater(this.props.updater + 1);
                              if (typeKey === "PAGE") {
                                this.props.setEditingIdx(null);
                                this.props.setIsCreateNewPage(true);
                              } else {
                                this.props.setEditingIdx(totalQuestions);
                              }
                            } catch (ex) {}
                            hide();
                            const main = document.getElementById("mian");
                            let mainHeight = main.scrollHeight;
                            main.scrollTo({
                              top: mainHeight + 120,
                              behavior: "smooth",
                            });
                          })}
                        >
                          {/* <Icon.RadioButtonChecked
                            size={FontSize.title}
                            color={"#585858"}
                          /> */}
                          <div className="icon">
                            <div
                              className="img-svg"
                              style={{
                                width: "100%",
                                height: "100%",
                                backgroundColor: `rgba(0, 0, 0, 0.65)`,
                                // backgroundImage: `url(${QUESTION_TYPE[typeKey].iconLink})`,
                                mask: `url(${QUESTION_TYPE[typeKey].iconLink}) no-repeat center`,
                                WebkitMask: `url(${QUESTION_TYPE[typeKey].iconLink}) no-repeat center`,
                                maskSize: "contain",
                                WebkitMaskSize: "contain",
                              }}
                            />
                          </div>
                          <div className="text" style={{ flex: 1 }}>
                            {QUESTION_TYPE[typeKey].display}
                          </div>
                        </div>
                      ))}
                    </div>
                  );
                case TABS[2].key:
                  if (nestedConfig === "look-n-feel") {
                    return (
                      <LookAndFeelConfig
                        goBack={() => this.setState({ nestedConfig: null })}
                        {...this.props}
                      />
                    );
                  }

                  return (
                    <div className="content-theme">
                      {/* <div className="description">選擇一種外觀主題</div> */}
                      <LookAndFeelConfig
                        goBack={() => this.setState({ nestedConfig: null })}
                        {...this.props}
                      />
                      {/* <div
                        className="theme-item"
                        onClick={async () => {
                          await Survey.Actions.updateThemePropertyById(
                            this.props.survey.id,
                            { styleType: "default" }
                          );
                          this.props.setUpdater(this.props.updater + 1);
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {this.props.survey.themeConfig?.styleType ===
                          "default" ? (
                            <Icon.RadioButtonChecked
                              size={FontSize.title}
                              color={"#585858"}
                            />
                          ) : (
                            <Icon.RadioButtonUnchecked
                              size={FontSize.title}
                              color={"#585858"}
                            />
                          )}

                          <Font.Body
                            style={{ color: Color.GreyBlack, marginLeft: 16 }}
                          >
                            企業識別色
                          </Font.Body>
                        </div>
                        <div className="theme-color-wrapper">
                          <div
                            className="theme-color"
                            style={{ backgroundColor: "#2882c5" }}
                          ></div>
                          <div
                            className="theme-color"
                            style={{ backgroundColor: "#f4f6fc" }}
                          ></div>
                          <div
                            className="theme-color"
                            style={{ backgroundColor: "#555555" }}
                          ></div>
                        </div>
                      </div>

                      <div
                        className="theme-item"
                        onClick={async () => {
                          await Survey.Actions.updateThemePropertyById(
                            this.props.survey.id,
                            { styleType: "customized" }
                          );
                          this.props.setUpdater(this.props.updater + 1);
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {this.props.survey.themeConfig?.styleType ===
                          "customized" ? (
                            <Icon.RadioButtonChecked
                              size={FontSize.title}
                              color={"#585858"}
                            />
                          ) : (
                            <Icon.RadioButtonUnchecked
                              size={FontSize.title}
                              color={"#585858"}
                            />
                          )}

                          <Font.Body
                            style={{ color: Color.GreyBlack, marginLeft: 16 }}
                          >
                            自訂
                          </Font.Body>
                        </div>
                        <Icon.KeyboardArrowRight
                          size={FontSize.largeTitle}
                          color={Color.GreyBlack_45}
                          onClick={() =>
                            this.setState({ nestedConfig: "look-n-feel" })
                          }
                        />
                      </div> */}
                    </div>
                  );
                default:
                  return null;
              }
            })()}
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fdfcfc;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);

  ${props =>
    props.disable &&
    "cursor: not-allowed; & .content { pointer-events: none; }"}

  & .tabs {
    display: flex;
    color: #585858;

    & .tab {
      flex: 1;
      padding: 15px 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-top: 4px solid transparent;
      color: rgba(0, 0, 0, 0.65);
      transition: all 0.2s linear;

      :hover {
        opacity: 0.7;
        background-image: linear-gradient(
          to bottom,
          rgba(131, 122, 232, 0.08) 1%,
          rgba(255, 255, 255, 0)
        );
      }

      ${props =>
        props.disable &&
        `color: rgba(0, 0, 0, 0.25);
         background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.05) 1%, rgba(255, 255, 255, 0));
        `}
    }

    & .tab.active {
      color: #534ab3;
      border-top: 4px solid #534ab3;
      background-image: linear-gradient(
        to bottom,
        rgba(131, 122, 232, 0.1) 1%,
        rgba(255, 255, 255, 0)
      );
      ${props =>
        props.disable &&
        `color: rgba(0, 0, 0, 0.25);
         border-top: 4px solid rgba(0, 0, 0, 0.25);
        `}
    }
  }

  & .content {
    overflow-y: overlay;
    & .content-question {
      & .question-type {
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 14px 24px 12px 24px;
        min-height: 48px;
        .icon {
          width: 20px;
          height: 20px;
          .img-svg {
          }
        }
        & > .text {
          margin-left: 16px;
          font-size: ${FontSize.body}px;
        }

        :hover {
          background-color: #edecf7;
        }
      }
    }

    & .content-setting {
      & .setting-item {
        cursor: pointer;
        padding: 14px 24px 12px 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 48px;
        .icon {
          width: 20px;
          height: 20px;
          .img-svg {
          }
        }
        & .text {
          margin-left: 16px;
          font-size: ${FontSize.body}px;
        }
      }
    }

    & .content-theme {
      & .description {
        padding: 5px 24px 9px 24px;
        font-size: ${FontSize.body}px;
        color: rgba(0, 0, 0, 0.65);
      }

      & .theme-item {
        cursor: pointer;
        padding: 14px 24px 12px 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 48px;

        & .theme-color-wrapper {
          display: flex;
          border: 1px solid #aab1b8;
          border-radius: 5px;
          width: 102px;
          height: 16px;
          & .theme-color {
            flex: 1;
          }
        }
      }
    }
  }
`;

export default SurveyDetailSideMenuConfig;
