import React, { useState } from "react";
import styled from "styled-components";
import * as Ant from "antd";
// import { PlusOutlined } from "@ant-design/icons";
import Widget, { Font, Color, FontSize } from "./Widget";
import LogicRule from "./LogicRule";
import LogicRuleDisplay from "./LogicRuleDisplay";
const key = "loading";

function SurveyDetailContentLogic(props) {
  const [filter, setFilter] = useState("all");
  if (!props.logicList) {
    //Ant.message.loading({ content: "載入中...", key });
    return; //<Empty />;
  }
  // 邏輯規則，如果沒有設定就給空
  if (!props.logicList || props.logicList.length === 0) {
    return <Empty />;
  }

  return (
    // <Wrapper disable={props.survey.audit}>
    <Wrapper>
      <div className="content" style={{ padding: "0 0 12px 0" }}>
        <Widget.FlexRow
          style={{
            paddingTop: 20,
            paddingLeft: 32,
            paddingRight: 20,
            paddingBottom: 20,
          }}
        >
          <Font.Title style={{ flex: 1 }}>邏輯規則列表</Font.Title>
          <AntdSelectFilterLogicCssOverwriteWrapper>
            <Ant.Select
              defaultValue="all"
              value={filter}
              style={{ width: 150, color: Color.Purple }}
              size="small"
              onChange={v => setFilter(v)}
            >
              <Ant.Select.Option value="all">{"顯示 全部"}</Ant.Select.Option>
              <Ant.Select.Option value="jump">{"跳題邏輯"}</Ant.Select.Option>
              <Ant.Select.Option value="prevent">
                {"選項防呆"}
              </Ant.Select.Option>
              <Ant.Select.Option value="forceQuit">
                {"強制結束問卷"}
              </Ant.Select.Option>
              <Ant.Select.Option value="preventByQuestion">
                {"跨題選項防呆"}
              </Ant.Select.Option>
            </Ant.Select>
          </AntdSelectFilterLogicCssOverwriteWrapper>
        </Widget.FlexRow>
        <div className="content-logicWrapper">
          {!props.logicList && (
            <div
              style={{
                height: "100vh",
                width: "100vw",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "fixed",
                zIndex: "999999",
              }}
            >
              <Ant.Spin />
            </div>
          )}
          {props.logicList &&
            props.logicList
              .filter(logic =>
                filter === "all"
                  ? true
                  : logic.type === filter || Number(logic.id)
              )
              .map((logic, idx) => {
                // if (props.editingIdx === idx) {
                // 邏輯規則主要元件
                if (props.editingIdx === logic.id) {
                  return (
                    <LogicRule
                      logic={logic}
                      idx={idx}
                      key={idx}
                      style={{ marginBottom: 20 }}
                      {...props}
                    />
                  );
                }
                return (
                  <LogicRuleDisplay
                    logic={logic}
                    idx={idx}
                    key={idx}
                    style={{ marginBottom: 8, marginLeft: 24, marginRight: 24 }}
                    {...props}
                  />
                );
              })}
        </div>
      </div>
    </Wrapper>
  );
}

function Empty() {
  return (
    <Wrapper>
      <div className="content">
        <Widget.FlexCol style={{ alignItems: "center", padding: "60px 10px" }}>
          {/* <div className="banner" /> */}
          <Ant.Empty />
          <Font.Body
            style={{
              margin: "30px 10px",
              textAlign: "center",
              fontSize: `${FontSize.subTitle}px`,
              color: Color.BlackBlack_1,
            }}
          >
            列表空白
            <br />
            請點選左邊工具列
          </Font.Body>
        </Widget.FlexCol>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  /* padding: 50px 20px; */
  width: 800px;
  height: auto;
  min-height: 100%;
  margin: 50px auto;
  display: flex;

  ${props => props.disable && "pointer-events: none;"}

  .banner {
    width: 160px;
    height: 160px;
    background-color: #ccc;
  }

  .content {
    width: 800px;
    margin: 0 auto;
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  }
`;

const AntdSelectFilterLogicCssOverwriteWrapper = styled.div`
  & .ant-select-arrow {
    color: ${Color.Purple};
  }

  & .ant-select-selector {
    border-color: ${Color.Purple}!important;
  }
`;

export default SurveyDetailContentLogic;
