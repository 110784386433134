import React, { useEffect, useState } from "react";
import * as Ant from "antd";
import styled from "styled-components";
import Widget, { Color, FontSize } from "./Widget";
import {
  InfoCircleFilled,
  CheckCircleFilled,
  EyeOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";

function ElementInput(settings) {
  let {
    width,
    height,
    size,
    className,
    placeholder,
    value,
    type,
    onChange,
    disable,
    isOk,
    isError,
    message,
    label,
    isSuffix,
    style,
  } = settings;
  const [widthValue, setWidthValue] = useState("100%");
  const [heightValue, setHeightValue] = useState("32px");
  const [isOkOrError, setIsOkOrError] = useState("");
  const [isMouseHover, setIsMouseHover] = useState(false);
  const [typeChange, setTypeChange] = useState(type);
  useEffect(() => {
    if (width) {
      setWidthValue(width);
    }
  }, [width]);

  useEffect(() => {
    if (height) {
      setHeightValue(height);
    }
    if (size) {
      switch (size) {
        case "lg":
          setHeightValue("40px");
          break;
        case "md":
          setHeightValue("32px");
          break;
        case "sm":
          setHeightValue("24px");
          break;
        default:
          break;
      }
    }
  }, [height, size]);

  useEffect(() => {
    if (isOk) {
      setIsOkOrError("ok");
    } else {
      setIsOkOrError("");
    }
  }, [isOk]);

  useEffect(() => {
    if (isError) {
      setIsOkOrError("error");
    } else {
      setIsOkOrError("");
    }
  }, [isError]);

  const onMouseToggle = () => {
    setIsMouseHover(!isMouseHover);
    if (isMouseHover) {
      setTypeChange("password");
    } else {
      setTypeChange("text");
    }
  };

  const onMouseOut = () => {
    setIsMouseHover(false);
    setTypeChange("password");
  };

  return (
    <Wrapper
      widthValue={widthValue}
      heightValue={heightValue}
      className={disable ? `disable ${isOkOrError}` : isOkOrError}
      style={style}
    >
      {label && <p className="label">{label}</p>}
      {isSuffix ? (
        label === "密碼" ? (
          <div style={{ position: "relative", maxWidth: 280 }}>
            <Ant.Input
              className={className}
              placeholder={placeholder}
              value={value}
              type={typeChange}
              onChange={onChange}
              disable={disable}
              suffix={
                isError ? (
                  <Ant.Tooltip title={message}>
                    <InfoCircleFilled className="icon" size={20} />
                  </Ant.Tooltip>
                ) : (
                  <Ant.Tooltip title={message}>
                    <CheckCircleFilled className="icon" size={20} />
                  </Ant.Tooltip>
                )
              }
            />
            {isError ? (
              ""
            ) : (
              <div
                onMouseDown={onMouseToggle}
                onMouseUp={onMouseToggle}
                onMouseOut={onMouseOut}
                style={{
                  width: 32,
                  height: 32,
                  position: "absolute",
                  top: 0,
                  right: 0,
                  zIndex: 2,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {isMouseHover ? (
                  <EyeOutlined size={20} />
                ) : (
                  <EyeInvisibleOutlined size={20} />
                )}
              </div>
            )}
          </div>
        ) : (
          <Ant.Input
            className={className}
            placeholder={placeholder}
            value={value}
            type={type}
            style={style}
            onChange={onChange}
            disable={disable}
            suffix={
              isError ? (
                <Ant.Tooltip title={message}>
                  <InfoCircleFilled className="icon" size={20} />
                </Ant.Tooltip>
              ) : (
                <Ant.Tooltip title={message}>
                  <CheckCircleFilled className="icon" size={20} />
                </Ant.Tooltip>
              )
            }
          />
        )
      ) : label === "密碼" ? (
        <div style={{ position: "relative", maxWidth: 280 }}>
          <Ant.Input
            className={className}
            placeholder={placeholder}
            value={value}
            type={typeChange}
            onChange={onChange}
            disable={disable}
          />
          <div
            onMouseDown={onMouseToggle}
            onMouseUp={onMouseToggle}
            onMouseOut={onMouseOut}
            style={{
              width: 32,
              height: 32,
              position: "absolute",
              top: 0,
              right: 0,
              zIndex: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {isMouseHover ? (
              <EyeOutlined size={20} />
            ) : (
              <EyeInvisibleOutlined size={20} />
            )}
          </div>
        </div>
      ) : (
        <span className="element-input-wrapper">
          <Ant.Input
            className={className}
            placeholder={placeholder}
            value={value}
            type={type}
            onChange={onChange}
            disabled={disable}
          />
        </span>
      )}
      {isOk || (isError && <p className="message">{message}</p>)}
    </Wrapper>
  );
}

export default ElementInput;

const Wrapper = styled.div`
  .element-input-wrapper {
    position: relative;
    display: block;
    transition: all 0.3s;
    .ant-input:placeholder-shown {
      top: 3px;
      background-color: transparent;
    }
  }
  .ant-input-affix-wrapper,
  .element-input-wrapper {
    width: ${props => props.widthValue} !important;
    height: ${props => props.heightValue} !important;
    border-radius: 2px;
    background-color: ${Color.White};
    border: solid 1px ${Color.Black_15};
    color: ${Color.Black_25};
    display: flex;
    justify-content: center;
    align-items: center;
    & > input.ant-input {
      top: 0 !important;
      border: none;
      outline: none;
      outline-style: none;
      box-shadow: none;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      border-color: transparent;
      -webkit-appearance: none;
      /* height: ${props => props.heightValue} !important; */
      font-size: ${FontSize.head}px;
      height: 32px;
      line-height: 32px;
      padding: 4px 11px;
      background-color: transparent;
    }
    & > input.ant-input::placeholder {
      font-size: ${FontSize.head}px;
      height: 32px;
      line-height: 32px;
      color: ${Color.Black_25};
      background-color: transparent;
    }
    & > input.ant-input::-webkit-input-placeholder {
      height: 32px;
      line-height: 32px;
    }
    & > input.ant-input:-moz-placeholder {
      height: 32px;
      line-height: 32px;
    }
    & > input.ant-input::-moz-placeholder {
      height: 32px;
      line-height: 32px;
    }
    & > input.ant-input:-ms-input-placeholder {
      height: 32px;
      line-height: 32px;
    }
    &:hover {
      border: solid 1px ${Color.MPurple_6_85};
      color: ${Color.Black_65};
      input.ant-input::placeholder {
        color: ${Color.Black_65};
      }
    }
    &:focus {
      /* -webkit-filter: blur(1px); */
      /* filter: blur(1px); */
      -webkit-box-shadow: 0 0 4px 2px ${Color.LightPurple_45};
      -moz-box-shadow: 0 0 4px 2px ${Color.LightPurple_45};
      box-shadow: 0 0 4px 2px ${Color.LightPurple_45};
      border: solid 1px ${Color.LightPurple_45};
      color: ${Color.Black_65};
      input.ant-input::placeholder {
        color: ${Color.Black_65};
      }
    }
    &:filled {
      border: solid 1px ${Color.MPurple_6_85};
      color: ${Color.Black_65};
      input.ant-input::placeholder {
        color: ${Color.Black_65};
      }
    }
    &:disabled,
    &[disable],
    &-disabled {
      border: solid 1px ${Color.Black_15};
      background-color: ${Color.Black_10};
      color: ${Color.Black_65};
      input.ant-input::placeholder {
        color: ${Color.Black_25};
      }
    }
    .ant-input-suffix {
      svg {
        width: 14px;
        height: 14px;
      }
    }
  }
  &.disable .ant-input-affix-wrapper,
  &.disable .element-input-wrapper {
    border: solid 1px ${Color.Black_15};
    color: ${Color.Black_65};
    background-color: #f5f5f5;
    /* background-color: ${Color.Black_10}; */
    cursor: not-allowed;
    input.ant-input::placeholder {
      color: ${Color.Black_25};
    }
  }
  .label {
    margin-bottom: 8px;
    font-family: "Noto Sans TC", "Helvetica Neue", "Consolas";
    font-size: ${FontSize.body}px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: ${Color.Black_85};
  }
  .message {
    margin-top: 2px;
    font-family: "Noto Sans TC", "Helvetica Neue", "Consolas";
    font-size: ${FontSize.caption}px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: justify;
  }
  &.ok {
    .ant-input-affix-wrapper,
    .element-input-wrapper {
      border: solid 1px ${Color.okGreen} !important;
      color: ${Color.Black_65}!important;
      input.ant-input::placeholder {
        color: ${Color.Black_65} !important;
      }
      .icon {
        width: 20;
        height: auto;
        color: ${Color.okGreen};
      }
    }
    .message {
      color: ${Color.okGreen};
    }
  }
  &.error {
    .ant-input-affix-wrapper,
    .element-input-wrapper {
      border: solid 1px ${Color.Red_6} !important;
      color: ${Color.Black_65} !important;
      input.ant-input::placeholder {
        color: ${Color.Black_65} !important;
      }
      .icon {
        width: 20;
        height: auto;
        color: ${Color.Red_6};
      }
    }
    .message {
      color: ${Color.Red_6};
    }
  }
`;
