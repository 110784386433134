import React, { useState, useEffect } from "react";
import * as Icon from "./Icon";
import Widget, { Color, Font, FontSize } from "./Widget";
import * as Ant from "antd";
import styled from "styled-components";
import * as Survey from "../Contexts/SurveyContext";
import * as SvgIcon from "./SvgIcon";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

function beforeUpload(file) {
  const isJpgOrPng =
    file.type === "image/jpeg" ||
    file.type === "image/png" ||
    file.type === "image/jpg";

  if (!isJpgOrPng) {
    Ant.message.error("圖片上傳判斷僅限於 PNG、JPG、JPEG 格式");
  }

  const isLt2M = file.size / 1024 / 1024 < 2;

  if (!isLt2M) {
    Ant.message.error("檔案限制為 2MB！");
  }

  return isJpgOrPng && isLt2M;
}

// const ColorPlatte = [
//   ["transparent", "#fff", "#fef445", "#fac725"],
//   ["#f24726", "#e6e6e6", "#cee741", "#8fd14f"],
//   ["#da2463", "#808080", "#13cdd4", "#0da789"],
//   ["#951fac", "#1a1a1a", "#2d9bf0", "#414cb2"],
// ];

// function ColorPickerInline(props) {
//   return (
//     <Widget.FlexCol>
//       {ColorPlatte.map((itm, idx) => (
//         <Widget.FlexRow key={idx}>
//           {itm.map(color => (
//             <ColorCircle
//               key={color}
//               color={color}
//               size={30}
//               style={{ cursor: "pointer" }}
//               onClick={() => props.pickColor(color)}
//             />
//           ))}
//         </Widget.FlexRow>
//       ))}
//     </Widget.FlexCol>
//   );

//   // return (
//   //   <Widget.FlexCol>
//   //     <Widget.FlexRow>
//   //       {ColorPlatte[0].map(color => (
//   //         <ColorCircle
//   //           key={color}
//   //           color={color}
//   //           size={30}
//   //           style={{ cursor: "pointer" }}
//   //           onClick={() => props.pickColor(color)}
//   //         />
//   //       ))}
//   //     </Widget.FlexRow>
//   //     <Widget.FlexRow>
//   //       {ColorPlatte[1].map(color => (
//   //         <ColorCircle
//   //           key={color}
//   //           color={color}
//   //           size={30}
//   //           style={{ cursor: "pointer" }}
//   //           onClick={() => props.pickColor(color)}
//   //         />
//   //       ))}
//   //     </Widget.FlexRow>
//   //     <Widget.FlexRow>
//   //       {ColorPlatte[2].map(color => (
//   //         <ColorCircle
//   //           key={color}
//   //           color={color}
//   //           size={30}
//   //           style={{ cursor: "pointer" }}
//   //           onClick={() => props.pickColor(color)}
//   //         />
//   //       ))}
//   //     </Widget.FlexRow>
//   //     <Widget.FlexRow>
//   //       {ColorPlatte[3].map(color => (
//   //         <ColorCircle
//   //           key={color}
//   //           color={color}
//   //           size={30}
//   //           style={{ cursor: "pointer" }}
//   //           onClick={() => props.pickColor(color)}
//   //         />
//   //       ))}
//   //     </Widget.FlexRow>
//   //     <div style={{ paddingLeft: 12, paddingBottom: 12, cursor: "pointer" }}>
//   //       <label htmlFor="theme-background-color">
//   //         <Icon.Add size={FontSize.largeTitle} color={Color.GreyBlack_45} />
//   //       </label>
//   //       <input
//   //         style={{ visibility: "hidden", width: 0, height: 0 }}
//   //         type="color"
//   //         id="theme-background-color"
//   //         name="theme-background-color"
//   //         value={props.color}
//   //         onChange={e => props.pickColor(e.target.value)}
//   //       />
//   //     </div>
//   //   </Widget.FlexCol>
//   // );
// }

function hide() {
  Ant.message.loading("更新中", 0);
}

export default function LookAndFeelConfig({ goBack, ...props }) {
  const themeConfig = props.survey.themeConfig;
  const {
    backgroundTheme: theme,
    backgroundColor: color,
    headFlag: flag,
    fontStyle: font,
  } = themeConfig;
  const webFile = themeConfig.headerImg;
  const mobileFile = themeConfig.mobileHeaderImg;
  // const [canShowColorPicker, setCanShowColorPicker] = useState(false);
  const [themeList, setThemeList] = useState([]);
  const [backgroundList, setBackgroundList] = useState([]);
  const [fontList, setFontList] = useState([]);

  // 2022-05-26: 企業版型
  // 資料應該是由 SurveyDetailPage 取得，props傳到子Component
  // 更新資料，並合併原始的props後，產出新資料
  const update = async themeProps => {
    const _themeProps = { ...themeProps, styleType: "customized" };
    const hide = Ant.message.loading("更新中...", 0);
    try {
      const _survey = await Survey.Actions.updateThemePropertyByIdAndReturnSurveyModel(
        props.survey.id,
        _themeProps,
        props.unModelSurvey
      );
      props.setSurvey(_survey);
    } catch (ex) {}
    hide();
  };

  // 更新是否啟用Header
  const updateFlag = async () => {
    const curr = flag === 1 ? 0 : 1;
    update({ headFlag: curr });
  };

  // 更新主題
  const updateTheme = async item => {
    const { id, data } = item;
    const { background = [] } = data;
    setBackgroundList(background);

    // 如果有變更主題，則須清除背景
    // 09-02 : 調整成每次更新主題時，預設選取第一個背景顏色
    update({ backgroundTheme: id, backgroundColor: background[0].color });
  };

  // 更新背景
  const updateColor = async item => {
    const { color } = item;
    update({ backgroundColor: color });
  };

  // 更新字體
  const updateFont = async code => {
    update({ fontStyle: code });
  };

  async function setWebFile(fileUrl) {
    update({ headerImg: fileUrl });
  }

  async function setMobileFile(fileUrl) {
    update({ mobileHeaderImg: fileUrl });
  }

  const _fileUploadProps = {
    action: ``,
    multiple: false,
    accept: "image/*",
    showUploadList: false,
  };

  function beforeUpload(file) {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg";

    if (!isJpgOrPng) {
      Ant.message.error("圖片上傳判斷僅限於 PNG、JPG、JPEG 格式");
    }

    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
      Ant.message.error("檔案限制為 2MB！");
    }

    return isJpgOrPng && isLt2M;
  }

  const _handleChange = async (type, info) => {
    let fileList = [...info.fileList];
    fileList = fileList.map(file => {
      return file;
    });

    let updatedFile = {
      ...fileList[fileList.length - 1],
    };

    if (updatedFile.originFileObj) {
      updatedFile.preview = await getBase64(updatedFile.originFileObj);

      const isJpgOrPng =
        updatedFile.type === "image/jpeg" ||
        updatedFile.type === "image/png" ||
        updatedFile.type === "image/jpg";

      const isLt2M = updatedFile.size / 1024 / 1024 < 2;

      if (isJpgOrPng && isLt2M) {
        updatedFile.preview = await getBase64(updatedFile.originFileObj);
        // if (beforeUpload(updatedFile)) {
        //   setWebPicture(updatedFile.preview);
        // }
        if (type === "web") {
          setWebFile(updatedFile.preview);
        } else {
          setMobileFile(updatedFile.preview);
        }
      }
    }

    // console.log("info", info);
    // if (info.event) {
    //   if (beforeUpload(updatedFile)) {
    //     if (type === "web") {
    //       setWebFile(updatedFile.preview);
    //     } else {
    //       setMobileFile(updatedFile.preview);
    //     }
    //   }
    // }
  };

  // 取得主題、字型下拉選單
  const fetchData = async () => {
    const [themeListRes, fontListRes] = await Promise.all([
      Survey.Actions.fetchThemeList(),
      Survey.Actions.fetchFontList(),
    ]);

    if (themeListRes.code == "200") {
      const themeList = themeListRes.data.map(itm => ({
        id: itm.Code,
        color: itm.Description,
        data: itm.Content,
      }));

      // 帶入之前設定
      if (themeList) {
        // 設定企業顏色
        setThemeList(themeList);
        if (theme) {
          // 設定背景顏色
          const themeObj = themeList.find(itm => itm.id === theme);
          if (themeObj) {
            const { data } = themeObj;
            const { background = [] } = data;
            setBackgroundList(background);
          }
        }
      }
    }

    if (fontListRes.code == "200") {
      setFontList(fontListRes.data);
    }
  };

  // 2022-05-26: 企業版型
  const ColorPickerInline = props => {
    const { type, list, onClick } = props;
    let value = type === "theme" ? theme : color;

    // 前處理資料，切成[[4],[4],....]陣列
    const process = [...list];
    let slice = [];
    for (var i = 0, len = process.length; i < len; i += 5) {
      slice.push(list.slice(i, i + 5));
    }

    return (
      <Widget.FlexCol>
        {slice.map((itm, idx) => (
          <Widget.FlexRow key={idx}>
            {itm.map(subItm => (
              <ColorCircle
                key={subItm.id}
                color={subItm.color}
                size={32}
                style={{ cursor: "pointer" }}
                onClick={() => onClick(subItm)}
                click={
                  type === "theme" ? value == subItm.id : value === subItm.color
                }
              />
            ))}
          </Widget.FlexRow>
        ))}
      </Widget.FlexCol>
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      {/* <Widget.FlexRow style={{ padding: 6 }}>
        <Icon.KeyboardArrowLeft
          size={FontSize.largeTitle}
          color={Color.GreyBlack_45}
          onClick={goBack}
        />
        <div style={{ flex: 1 }}>自訂</div>
      </Widget.FlexRow> */}

      <Widget.Line css="background-color: rgba(0, 0, 0, 0.1);" />

      <Widget.FlexCol style={{ padding: "16px 20px 24px" }}>
        <Widget.FlexRow style={{ marginBottom: 16 }}>
          <Font.Head style={{ flex: 1 }}>問卷上方圖片(Header)</Font.Head>
          <Widget.DetailedSwitch
            size={"small"}
            checked={flag === 1}
            onClick={updateFlag}
          />
        </Widget.FlexRow>
        <Font.Body style={{ marginBottom: 4 }}>網頁版</Font.Body>
        <Font.Body
          style={{
            color: Color.LightGold,
            fontSize: `${FontSize.caption}px`,
            marginBottom: 12,
          }}
        >
          最佳尺寸為寬800px * 高60px
          <br />
          若圖片大小不符合, 系統將自動最佳化
        </Font.Body>
        <PreviewBox
          style={{ alignSelf: "stretch", marginBottom: 8, minHeight: 18 }}
        >
          {webFile && (
            <div className="img-mask">
              <img src={webFile} style={{ width: "100%" }} />
              <div className="del-mask"></div>
              <SvgIcon.EditorDelete
                color={Color.GreyWhite}
                className="delete"
                onClick={() => setWebFile("")}
              ></SvgIcon.EditorDelete>
            </div>
          )}
        </PreviewBox>
        <Widget.FlexRow
          style={{ marginBottom: 24, justifyContent: "space-between" }}
        >
          <Ant.Upload
            {..._fileUploadProps}
            beforeUpload={beforeUpload}
            onChange={_handleChange.bind(null, "web")}
          >
            <Ant.Button
              style={{
                alignSelf: "flex-start",
                border: "1px solid rgba(83, 74, 179, 0.85)",
                color: "#534ab3",
                width: 80,
                height: 24,
                padding: 0,
              }}
            >
              上傳圖片
            </Ant.Button>
          </Ant.Upload>
          {/* <a onClick={() => setWebFile("")}>恢復預設值</a> */}
          <ResetBtn onClick={() => setWebFile("")}>恢復預設值</ResetBtn>
        </Widget.FlexRow>
        <Font.Body style={{ marginBottom: 4 }}>手機版</Font.Body>
        <Font.Body
          style={{
            marginBottom: 16,
            color: Color.LightGold,
            fontSize: `${FontSize.caption}px`,
          }}
        >
          最佳尺寸為寬414px * 高50px
          <br />
          若圖片大小不符合, 系統將自動最佳化
        </Font.Body>
        <PreviewBox style={{ alignSelf: "stretch", marginBottom: 8 }}>
          {mobileFile && (
            <div className="img-mask">
              <img src={mobileFile} style={{ width: "100%" }} />
              <div className="del-mask"></div>
              <SvgIcon.EditorDelete
                color={Color.GreyWhite}
                className="delete"
                onClick={() => setMobileFile("")}
              ></SvgIcon.EditorDelete>
            </div>
          )}
        </PreviewBox>
        <Widget.FlexRow style={{ justifyContent: "space-between" }}>
          <Ant.Upload
            {..._fileUploadProps}
            beforeUpload={beforeUpload}
            onChange={_handleChange.bind(null, "mobile")}
          >
            <Ant.Button
              style={{
                alignSelf: "flex-start",
                border: "1px solid rgba(83, 74, 179, 0.85)",
                color: "#534ab3",
                width: 80,
                height: 24,
                padding: 0,
              }}
            >
              上傳圖片
            </Ant.Button>
          </Ant.Upload>
          {/* <a onClick={() => setMobileFile("")}>恢復預設值</a> */}
          <ResetBtn onClick={() => setMobileFile("")}>恢復預設值</ResetBtn>
        </Widget.FlexRow>
      </Widget.FlexCol>

      <Widget.Line css="background-color: rgba(0, 0, 0, 0.1);" />

      <Widget.FlexCol style={{ padding: 20 }}>
        <Widget.FlexRow style={{ marginBottom: 10 }}>
          <Font.Head style={{ flex: 1 }}>主題顏色</Font.Head>
        </Widget.FlexRow>
        <ColorPicker className="show">
          <ColorPickerInline
            list={themeList}
            onClick={updateTheme}
            type="theme"
          />
        </ColorPicker>
      </Widget.FlexCol>

      <Widget.Line css="background-color: rgba(0, 0, 0, 0.1);" />

      <Widget.FlexCol style={{ padding: 20 }}>
        <Font.Head style={{ marginBottom: "16px" }}>背景顏色</Font.Head>
        <ColorPicker className="show">
          <ColorPickerInline
            list={backgroundList}
            onClick={updateColor}
            type="color"
          />
        </ColorPicker>
      </Widget.FlexCol>
      <Widget.Line css="background-color: rgba(0, 0, 0, 0.1);" />
      <Widget.FlexCol style={{ padding: "16px 20px 50px" }}>
        <Font.Head style={{ marginBottom: "8px" }}>字型樣式</Font.Head>
        <Ant.Select value={font} onChange={updateFont} style={{ fontSize: 16 }}>
          {fontList.map(itm => (
            <Ant.Select.Option key={itm.Code} value={itm.Code}>
              {itm.Name}
            </Ant.Select.Option>
          ))}
        </Ant.Select>
      </Widget.FlexCol>
    </div>
  );
}

const ColorPicker = styled.div`
  transition: all 0.2s ease;
  opacity: 0;
  &.show {
    opacity: 1;
  }
`;

const PreviewBox = styled.div`
  border: 1px dashed #ccc;
  min-height: 30px;
  position: relative;

  .img-mask {
    .del-mask {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      background-color: rgba(15, 14, 35, 0.5);
    }

    .delete {
      position: absolute;
      cursor: pointer;
      top: 10px;
      right: 12px;
    }
  }
`;

const ColorBox = styled.div`
  position: relative;
  overflow: hidden;
  border: 1px solid grey;
  background-color: ${props => props.color};
  height: 20px;
  width: 100px;

  & :before {
    ${props => (props.color === "transparent" ? "content: '';" : "")}
    width: 1px;
    display: block;
    height: 120px;
    background-color: #a6a6a6;
    position: absolute;
    transform: rotate(-79.5deg);
    transform-origin: top;
  }
`;

const ColorCircle = styled.div`
  position: relative;
  overflow: hidden;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border-radius: ${props => props.size / 2}px;
  /* border: 1px solid #a6a6a6; */
  background-color: ${props => props.color};
  margin: 0px 12px 12px 0px;

  & :before {
    ${props => (props.color === "transparent" ? "content: '';" : "")}
    width: 1px;
    display: block;
    height: 40px;
    background-color: #a6a6a6;
    position: absolute;
    transform: rotate(-45deg) translateX(13px);
  }
  // 2022-05-26: 企業版型
  & :hover {
    opacity: 0.5;
    box-shadow: 0px 3px 8px #00000047;
  }
  ${props =>
    props.click
      ? `
      box-shadow: 0px 3px 8px #00000047;
      &:after {
    content: '';
    position: absolute;
    width: 10px;
    height: 15px;
    border-right: 2px solid black;
    border-bottom: 2px solid black;
    top: 5px;
    right: 10px;
    opacity: 0.5;
    transform: rotate(45deg);`
      : ""}
`;

const ResetBtn = styled.div`
  width: 94px;
  height: 24px;
  padding: 1px 12px;
  color: #534ab3;
  font-size: 14px;
  background-color: rgba(255, 255, 255, 0);
  cursor: pointer;

  &:hover {
    color: #837ae8;
    background-color: rgba(237, 236, 247, 0.5);
  }

  &:active {
    color: #534ab3;
    background-color: #dcdaef;
  }

  &:focus {
    color: #756ec2;
    background-color: rgba(237, 236, 247, 0.5);
  }

  &.disabled {
    color: rgba(0, 0, 0, 0.25);
    background-color: rgba(255, 255, 255, 0);
  }
`;
