import React, { useState, useEffect } from "react";
import _ from "lodash";
import styled from "styled-components";
import * as Ant from "antd";
import {
  PlusOutlined,
  CloseCircleFilled,
  CloseOutlined,
} from "@ant-design/icons";

import Widget, { Font, Color, FontSize } from "../Widget";
import * as Icon from "../Icon";
import { catText } from "../../Utils/RichTextUtils";
import * as SvgIcon from "../SvgIcon";

// 顯示數字對應中文用
const cnNums = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"];

// 額滿結束動作選單
const actionOptions = [
  {
    value: "0",
    text: "跳到額滿結束頁",
  },
  {
    value: "1",
    text: "已額滿，請選擇其他選項",
  },
];

/**
 * 設定預設格式
 * optionList : [,]
 * limitQty : int
 * actionType : int
 */
const defaultSetting = { optionList: [], limitQty: null, actionType: "0" };

const LimitModal = props => {
  const {
    survey,
    provideType,
    setLimitVisible,
    limitVisible,
    limitSetting,
    setLimitSetting,
    values,
    setValues,
  } = props;

  const [isValidated, setIsValidated] = useState({});
  /**
   * 放 本次所選擇之題目 id
   * [Qusetind 1 Id, Question 2 Id]
   */
  const [questions, setQuestion] = useState([null]);

  /**
   * 放 本次所選擇之值，ex
   * [
   *  { optionList: [,], limitQty: 1, actionType: "0"},
   *  { optionList: [,], limitQty: 2, actionType: "1"},
   * ]
   */
  const [setting, setSetting] = useState([
    { optionList: [], limitQty: null, actionType: "0" },
  ]);

  // 是否顯示提示
  const [errorTip, setErrorTip] = useState(false);

  /**
   * 放 第一個題目選項、第二個題目選項
   * [[Question 1 Options], [Question 2 Options]]
   */
  const [optionListGroup, setOptionListGroup] = useState([]);

  // 判斷需設定幾題題目
  const questionCondition = 2;

  // 是否為多題題目
  const isMuti = questions.length === questionCondition;

  // 產生題目選項
  const genQuestionOpt = (qid, idx) => {
    const { questions: _questions } = props.survey;
    let filterQuestion = [];
    if (idx === 0) {
      filterQuestion = _questions.filter(
        question => question.type === "SINGLE_CHOICE"
        // || question.type === "MULTIPLE_CHOICE"
      );
    } else {
      const prevQuestion = _questions.find(
        question => question.id == questions[idx - 1]
      );
      // console.log("prevQuestion", prevQuestion, questions, idx);
      // console.log("prevQuestion", questions[idx - 1], idx);

      filterQuestion = _questions.filter(
        question =>
          (question.type === "SINGLE_CHOICE" ||
            question.type === "MULTIPLE_CHOICE") &&
          question.idx > prevQuestion.idx
      );
    }
    return filterQuestion.map(question => (
      <Ant.Select.Option
        key={question.id}
        value={question.id}
        questionoptions={question.options}
      >
        {`${question.getIdxDisplay()}. ${catText(question.content)}`}
      </Ant.Select.Option>
    ));
  };

  const getOptionByQuestionData = questionList => {
    if (questionList) {
      const _optionListGroup = [];
      questionList.forEach(currQuestionId => {
        const find = props.survey.questions.find(q => q.id === currQuestionId);
        if (find) _optionListGroup.push(find.options);
      });
      setOptionListGroup(_optionListGroup);
    }
  };

  const addQuestion = () => {
    if (questions.length === 2) {
      Ant.message.error("最多僅能設定2個條件");
      return;
    } else if (questions.length === 1 && questions[0] === null) {
      Ant.message.error("請先選擇第一個題目選項");
      return;
    }

    let _questions = [...questions];
    _questions.push(null);
    setQuestion(_questions);

    let _optionListGroup = [...optionListGroup];
    _optionListGroup.push([]);
    setOptionListGroup(_optionListGroup);

    let _setting = [...setting];
    _setting.forEach(itm => {
      let _optionList = [...itm.optionList];
      _optionList.push(null);
      itm.optionList = _optionList;
    });
    setSetting(_setting);
  };

  const removeQuestion = idx => {
    if (questions.length === 1) {
      Ant.message.error("最少需要一個題目與一個選項");
      setErrorTip(true);
      return;
    }
    // 移除刪除題型
    setQuestion(pre => {
      const _pre = [...pre];
      _pre.splice(idx, 1);
      return _pre;
    });

    // 移除刪除題型對應Option
    setOptionListGroup(pre => {
      const _pre = [...pre];
      _pre.splice(idx, 1);
      return _pre;
    });

    // 移除刪除題型對應所選結果
    let _setting = [...setting];
    _setting.forEach(itm => {
      let _optionList = [...itm.optionList];
      _optionList.splice(idx, 1);
      itm.optionList = _optionList;
    });
    setSetting(_setting);
  };

  const changeQuestion = (idx, value, option) => {
    // console.log("changeQuestion", idx, value, option);
    // 更新選擇的 Question value
    const _questions = [...questions];
    _questions[idx] = value;
    setQuestion(_questions);

    // 更新選單
    const _optionListGroup = [...optionListGroup];
    _optionListGroup[idx] = option.questionoptions;
    setOptionListGroup(_optionListGroup);

    // 清除已選擇的對應的setting option
    let _setting = [...setting];
    _setting.map(s => (s.optionList[idx] = null));
    setSetting(_setting);
  };

  const addSetting = () => {
    if (questions.filter(Boolean).length === 0) {
      Ant.message.warning("請先選擇一個題目");
      return;
    }

    let _setting = [...setting];

    let _optionList = [];
    questions.forEach(() => _optionList.push(null));

    let _defaultSetting = { ...defaultSetting, optionList: _optionList };
    _setting.push(_defaultSetting);
    setSetting(_setting);
  };

  const removeSetting = idx => {
    if (questions.length === 1 && setting.length === 1) {
      Ant.message.error("最少需要一個題目與一個選項");
      return;
    }
    let _setting = [...setting];
    _setting.splice(idx, 1);
    setSetting(_setting);
  };

  const updateOption = (idx, columnIdx, value) => {
    let _setting = [...setting];
    _setting[idx]["optionList"][columnIdx] = value;
    setSetting(_setting);
  };

  const updateSetting = (idx, column, value) => {
    if (column === "limitQty") {
      const val = Number(value);
      if (Number.isNaN(val)) return;
      let _setting = [...setting];
      _setting[idx][column] = value === "" ? "" : val < 1 ? "1" : value;
      setSetting(_setting);
      return;
    }
    let _setting = [...setting];
    _setting[idx][column] = value;
    setSetting(_setting);
  };

  const listAllQuestOption = () => {
    if (questions.filter(Boolean).length === 0) {
      Ant.message.warning("請先選擇一個題目");
      return;
    }

    let _listAllQuestOption = [];
    let _setting = [...setting];

    if (optionListGroup.length > 0) {
      if (Array.isArray(optionListGroup[0])) {
        optionListGroup[0].map(option => {
          let _optionList = [];
          _optionList.push(option.id);

          if (
            optionListGroup.length == 2 &&
            Array.isArray(optionListGroup[1])
          ) {
            optionListGroup[1].map(option1 => {
              let _optionList1 = [..._optionList];
              _optionList1.push(option1.id);

              _listAllQuestOption.push({
                ...defaultSetting,
                optionList: _optionList1,
              });
            });
          } else {
            _listAllQuestOption.push({
              ...defaultSetting,
              optionList: _optionList,
            });
          }
        });
      }
    }
    // console.log("_listAllQuestOption", _listAllQuestOption);

    // 整理，將已填資料放回新產生的資料中
    if (Array.isArray(_setting)) {
      _setting.map(_set => {
        const idx = _listAllQuestOption.findIndex(
          e => JSON.stringify(e.optionList) === JSON.stringify(_set.optionList)
        );
        if (idx > -1) {
          _listAllQuestOption[idx] = _set;
        }
      });
    }
    setSetting(_listAllQuestOption);
  };

  async function onOk() {
    let validateObj = {};
    setting.forEach((s, idx) => {
      s.optionList.forEach((_, i) => {
        validateObj[`optionList_${idx}_${i + 1}`] = true;
      });
      validateObj[`limitQty_${idx}`] = true;
    });
    setIsValidated(validateObj);

    // 確認是否有不符合驗證的資料
    const find = setting.find(itm => {
      if (itm.optionList.find(o => !o)) {
        return true;
      }
      if (!itm.limitQty || itm.limitQty < 1 || itm.limitQty === "") {
        return true;
      }
      return false;
    });

    if (find) {
      return;
    } else {
      const apiData = settingToLimit();

      let count = 0;
      setting.forEach(itm => (count += parseInt(itm.limitQty)));
      setValues({ ...values, upperBound: count });
      setLimitSetting(apiData);
      setLimitVisible(false);
    }
  }

  const onCancel = () => {
    const propsOnCancel = _.get(props, "onCancel");
    if (_.isFunction(propsOnCancel)) propsOnCancel();
    setLimitVisible(false);
  };

  // 將由 API 取得資料轉成 Component 可使用的形式
  // 回給父組件時，會呼叫 settingToLimit() 重組
  const limitToSetting = data => {
    if (data) {
      const { QuestionList, Options } = data;
      setQuestion(QuestionList);
      getOptionByQuestionData(QuestionList);

      let settingData = [];
      Options.forEach(option => {
        let copySettingData = { ...defaultSetting };
        copySettingData.optionList = option.OptionList;
        copySettingData.limitQty = option.LimitQty;
        copySettingData.actionType = option.ActionType;

        settingData.push(copySettingData);
      });

      setSetting(settingData);
      // return settingData;
    }
    // else {
    //   return [];
    // }
  };

  // 整理要回給Api的Data
  const settingToLimit = () => {
    let apiData = {};
    let apiDataOptions = [];

    // 雖然檢核有檔，但是組合還資料時，還是多過濾一次，確保安全
    setting.forEach(itm => {
      apiDataOptions.push({
        OptionList: itm.optionList.filter(o => o),
        LimitQty: itm.limitQty,
        ActionType: itm.actionType,
      });
    });

    apiData.QuestionList = questions.filter(q => q);
    apiData.Options = apiDataOptions;
    // console.log('questions', questions, "setting", setting);
    return apiData;
  };

  useEffect(() => {
    limitToSetting(limitSetting);
  }, []);

  return (
    <Ant.Modal
      title="交叉組合回覆數量限制"
      visible={limitVisible}
      bodyStyle={{ padding: 0 }}
      onCancel={() => onCancel()}
      onOk={() => onOk()}
      okText="確定"
      cancelText="取消"
      width={936}
      destroyOnClose
    >
      <Wrapper isMuti={isMuti}>
        <Widget.FlexRow
          style={{
            backgroundColor: "rgba(15, 14, 35, 0.7)",
            padding: "12px 40px 12px 36px",
          }}
        >
          <Icon.InfoCircleOutlined
            color="white"
            css="width: 20px; height: 20px;"
            className="svg-100"
            style={{ alignSelf: "flex-start" }}
          />
          <Font.Body style={{ flex: 1, marginLeft: 12, color: "white" }}>
            交叉組合回覆數量上限多應用於報名人數、商品數量固定的問卷裡，當交叉選項數量達上限時，填答者就不能再選擇該選項，藉此控制回收有效問卷數量。
          </Font.Body>
        </Widget.FlexRow>
        {errorTip && (
          <Widget.FlexRow
            style={{
              backgroundColor: Color.Red_1,
              padding: "12px 40px 12px 36px",
            }}
          >
            <CloseCircleFilled className="closeCircleFilled" />
            <Font.Body style={{ flex: 1, marginLeft: 15 }}>
              最少須選擇一個題目
            </Font.Body>
            <CloseOutlined
              className="closeOutlined"
              onClick={() => setErrorTip(false)}
            />
          </Widget.FlexRow>
        )}
        <Widget.FlexCol style={{ padding: "16px 40px 33px 40px" }}>
          <Font.Head style={{ marginBottom: "16px" }}>
            步驟一 選擇題目
          </Font.Head>
          <Font.Body style={{ marginBottom: "4px" }}>題目</Font.Body>
          <Widget.FlexCol>
            {questions.map((itm, idx) => (
              <Widget.FlexRow key={idx} style={{ marginBottom: 12 }}>
                <Ant.Select
                  style={{ width: 820, height: 32 }}
                  placeholder={`第${cnNums[idx + 1]}個題目`}
                  value={itm || undefined}
                  onChange={(value, option) =>
                    changeQuestion(idx, value, option)
                  }
                >
                  {genQuestionOpt(itm, idx)}
                </Ant.Select>
                <div
                  style={{
                    backgroundColor: Color.LightPurple,
                    borderRadius: "12px",
                    marginLeft: 12,
                    cursor: "pointer",
                  }}
                >
                  <Icon.Remove
                    size={FontSize.largeTitle}
                    color={Color.GreyBlack}
                    onClick={() => removeQuestion(idx)}
                  />
                </div>
              </Widget.FlexRow>
            ))}
          </Widget.FlexCol>
          <Widget.FlexRow>
            <Ant.Button
              type="link"
              size="small"
              icon={<PlusOutlined size={14} />}
              style={{ width: 74 }}
              onClick={e => addQuestion()}
            >
              新增
            </Ant.Button>
            <Widget.FlexRow style={{ marginLeft: 12 }}>
              <Icon.InfoCircleOutlined color={Color.LightGold} />
              <Font.Body style={{ marginLeft: 8, color: Color.LightGold }}>
                {`最多僅能設定2個條件`}
              </Font.Body>
            </Widget.FlexRow>
          </Widget.FlexRow>
          <Ant.Divider style={{ margin: "33px 0px 23px" }}></Ant.Divider>
          <Font.Head style={{ marginBottom: "8px" }}>
            步驟二 設定選項額滿上限
          </Font.Head>
          <Font.Body
            style={{
              padding: "8px 12px",
              backgroundColor: "rgba(109, 190, 255, 0.2)",
              color: Color.ChtBlue_8,
              borderRadius: "8px",
              marginBottom: "24px",
            }}
          >
            選項數量未達上限時，已開啟問卷的填寫者仍可以選擇與送出選項，故同時大量回覆可能有超出數量的情況發生。
          </Font.Body>
          <div className="content">
            <div className="setting-content">
              <div className="question-block">
                <div className="question-1 desc">第一個題目選項</div>
                {isMuti && (
                  <div className="question-2 desc">第二個題目選項</div>
                )}
              </div>
              <div className="setting-block">
                <div className="qty desc">
                  <Ant.Tooltip title="最小值需要為1，如果不希望該選項設定數量上限，則刪除該選項。">
                    <SvgIcon.Help
                      size={24}
                      color="#ffc53d"
                      style={{ marginRight: 6 }}
                    />
                  </Ant.Tooltip>
                  數量上限
                </div>
                <div className="action desc">選項額滿的執行動作</div>
                <div style={{ width: 36 }}></div>
              </div>
            </div>
            {setting.map((itm, idx) => (
              <div key={idx} className="setting-content">
                <div className="question-block">
                  <div className="question-1">
                    <Ant.Select
                      style={{ width: "100%" }}
                      placeholder="選項"
                      value={itm.optionList[0] || undefined}
                      onChange={value => updateOption(idx, 0, value)}
                    >
                      {optionListGroup.length > 0 &&
                        optionListGroup[0].map((groupItm, i) => (
                          <Ant.Select.Option
                            key={groupItm.id}
                            value={groupItm.id}
                          >
                            {`${i + 1}. ${groupItm.value}`}
                          </Ant.Select.Option>
                        ))}
                    </Ant.Select>
                    {isValidated[`optionList_${idx}_1`] &&
                      !itm.optionList[0] && (
                        <div className="errorText">請選擇題目</div>
                      )}
                  </div>
                  {isMuti && (
                    <div className="question-2">
                      <Ant.Select
                        style={{ width: "100%" }}
                        placeholder="選項"
                        value={itm.optionList[1] || undefined}
                        onChange={value => updateOption(idx, 1, value)}
                      >
                        {optionListGroup.length > 1 &&
                          optionListGroup[1].map((groupItm, i) => (
                            <Ant.Select.Option
                              key={groupItm.id}
                              value={groupItm.id}
                            >
                              {`${i + 1}. ${groupItm.value}`}
                            </Ant.Select.Option>
                          ))}
                      </Ant.Select>
                      {isValidated[`optionList_${idx}_2`] &&
                        !itm.optionList[1] && (
                          <div className="errorText">請選擇題目</div>
                        )}
                    </div>
                  )}
                </div>
                <div className="setting-block">
                  <div className="qty">
                    <Ant.Input
                      value={itm.limitQty}
                      placeholder="最少數量為 1"
                      onChange={e =>
                        updateSetting(idx, "limitQty", e.target.value)
                      }
                    />
                    {isValidated[`limitQty_${idx}`] &&
                      (!itm.limitQty ||
                        itm.limitQty < 1 ||
                        itm.limitQty === "") && (
                        <div className="errorText">輸入數值或刪除選項</div>
                      )}
                  </div>
                  <div className="action">
                    <Ant.Select
                      value={itm.actionType || undefined}
                      style={{ width: "100%" }}
                      onChange={value =>
                        updateSetting(idx, "actionType", value)
                      }
                    >
                      {actionOptions.map(itm => (
                        <Ant.Select.Option key={itm.value} value={itm.value}>
                          {itm.text}
                        </Ant.Select.Option>
                      ))}
                    </Ant.Select>
                  </div>
                  <div className="dele">
                    <Icon.Remove
                      size={FontSize.largeTitle}
                      color={Color.GreyBlack}
                      onClick={() => removeSetting(idx)}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <Widget.FlexRow className="function-block">
            <Ant.Button
              type="link"
              size="small"
              icon={<PlusOutlined size={14} />}
              style={{ width: 74, marginRight: 12 }}
              onClick={() => addSetting()}
            >
              新增
            </Ant.Button>
            <Ant.Button
              type="link"
              size="small"
              // icon={<PlusOutlined size={14} />}
              style={{
                width: 102,
              }}
              onClick={() => listAllQuestOption()}
            >
              列出所有選項
            </Ant.Button>
          </Widget.FlexRow>
        </Widget.FlexCol>
      </Wrapper>
    </Ant.Modal>
  );
};

const Wrapper = styled.div`
  .content:first-child {
    margin-bottom: 8px;
  }

  .content {
    .setting-content {
      display: flex;
      justify-content: space-between;

      &:first-child {
        margin-bottom: 8px;
      }

      &:not(:nth-child(-n + 2)) {
        margin-top: 16px;
      }

      .question-block {
        display: flex;
        flex-direction: row;

        .question-1 {
          width: ${props => (props.isMuti ? "200" : "349")}px;
          margin-right: 8px;

          &.desc {
            line-height: 1;
            display: flex;
            align-items: center;
          }
        }

        .question-2 {
          width: 200px;

          &.desc {
            line-height: 1;
            display: flex;
            align-items: center;
          }
        }
      }

      .setting-block {
        display: flex;
        flex-direction: row;

        .qty {
          width: 117px;
          margin-right: 8px;

          &.desc {
            text-align: right;
            line-height: 1;
            display: flex;
            align-items: center;
            justify-content: flex-end;
          }

          > input {
            text-align: right;

            ::-webkit-input-placeholder {
              text-align: right;
            }
          }
        }

        .action {
          width: ${props => (props.isMuti ? "255" : "314")}px;

          &.desc {
            text-align: left;
            line-height: 1;
            display: flex;
            align-items: center;
          }
        }

        .dele {
          width: 24px;
          height: 24px;
          margin-left: 12px;
          margin-top: 5px;
          cursor: pointer;
          background-color: ${Color.LightPurple};
          border-radius: 12px;
        }
      }
    }
  }

  .function-block {
    margin-top: 13px;
  }

  .closeCircleFilled {
    color: red;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .closeOutlined {
    margin-right: -20px;
    color: rgba(0, 0, 0, 0.25);
    svg {
      width: 1em;
      height: 1em;
    }
  }

  .errorText {
    color: red;
    line-height: 1;
    font-size: ${FontSize.caption}px;
    margin-top: 7px;
  }
`;

export default LimitModal;
