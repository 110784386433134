import React, { useMemo, useState, useEffect } from "react";
import styled from "styled-components";
import * as Ant from "antd";
import * as Icon from "./Icon";
import * as SvgIcon from "./SvgIcon";
import Widget, { FontSize, Color, Font } from "./Widget";
import clickOnce from "../Utils/ClickOnce";
import ElementInput from "./ElementInput";
import * as Survey from "../Contexts/SurveyContext";
import { withReact, Slate, ReactEditor } from "slate-react";
import { createEditor } from "slate";
import { withHistory } from "slate-history";
import TextEndPageEdit from "./EndPage.text";
import RichTextEditor from "./RichTextEditor";
const RichTextUtils = require("../Utils/RichTextUtils");
// 編輯問卷結束頁面
function PageHeader() {
  return (
    <>
      <div className="header-wrapper">
        <div className="header">結束頁</div>
      </div>
    </>
  );
}

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
function beforeUpload(file) {
  const isJpgOrPng =
    file.type === "image/jpeg" ||
    file.type === "image/png" ||
    file.type === "image/jpg";

  if (!isJpgOrPng) {
    Ant.message.error("圖片上傳判斷僅限於 PNG、JPG、JPEG 格式");
  }

  const isLt2M = file.size / 1024 / 1024 < 2;

  if (!isLt2M) {
    Ant.message.error("檔案限制為 2MB！");
  }

  return isJpgOrPng && isLt2M;
}

export default function SurveyFinish({
  survey,
  lastPage,
  pageIdx,
  begin,
  end,
  setUpdater,
  updater,
  ...props
}) {
  const _fileUploadProps = {
    action: ``,
    multiple: false,
    accept: "image/*",
    showUploadList: false,
    headers: {
      accessControlAllowOrigin: "",
      AccessControlAllowOrigin: "*",
      accessControlAllowMethods: "POST",
      accessControlAllowHeaders: "x-requested-with,content-type",
    },
  };

  const _handleChange = async info => {
    let fileList = [...info.fileList];
    fileList = fileList.map(file => {
      return file;
    });

    let updatedFile = {
      ...fileList[fileList.length - 1],
    };
    //console.log("updatedFile.originFileObj", updatedFile.originFileObj);
    // if (updatedFile.originFileObj) {
    //   updatedFile.preview = await getBase64(updatedFile.originFileObj);
    // }
    if (updatedFile.originFileObj) {
      updatedFile.preview = await getBase64(updatedFile.originFileObj);
      if (beforeUpload(updatedFile)) setWebPicture(updatedFile.preview);
    }
  };
  const [loading, setLoading] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [isRedirect, setIsRedirect] = useState(true);
  const [buttonText, setButtonText] = useState("關閉視窗");
  const [title, setTitle] = useState(
    '[{"type":"paragraph","children":[{"text":"範例?"}]}]'
  );
  const [linkText, setLinkText] = useState(
    "https://www.cht.com.tw/home/consumer"
  );
  const [isLinkTextBlank, setIsLinkTextBlank] = useState(false);
  const [isLinkTextFormatWrong, setIsLinkTextFormatWrong] = useState(false);
  const [webPicture, setWebPicture] = useState("../images/EndPage_Web.png");

  const initialValue = [
    {
      type: "align-center",
      children: [
        {
          type: "paragraph",
          children: [
            {
              text: "感謝您的參與！",
              size: "24",
              bold: true,
              color: "#0075d3",
            },
          ],
        },
      ],
    },
  ];

  const initialEndPage = {
    EndPagePic: "", //"https://hamipoint.cht.com.tw/images/cht-logo.png",
    EndPageStyle: initialValue, //endPageData.EndPageStyle
    ButtonSentence: "關閉視窗",
    EnableRedirect: true,
    RedirectUrl: "https://www.cht.com.tw/home/consumer",
  };

  const [endPageData, setEndPageData] = useState(initialEndPage);
  const [content, setContent] = useState();
  const [oldContent, setOldContent] = useState();
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        // 先去呼叫API結束頁資訊
        let data = await Survey.Actions.fetchEndPage(survey.id);

        data = {
          SurveyId: survey.id,
          EndPagePic: data.EndPagePic,
          EndPageStyle: data.EndPageStyle
            ? JSON.parse(data.EndPageStyle)
            : initialValue,
          ButtonSentence: data.ButtonSentence,
          EnableRedirect: data.EnableRedirect,
          RedirectUrl: data.RedirectUrl,
        };
        data = data.SurveyId ? data : initialEndPage;
        setEndPageData(data);
        setContent(data.EndPageStyle);
        setOldContent(data.EndPageStyle);
        setButtonText(
          data.ButtonSentence === "" || data.ButtonSentence === undefined
            ? "關閉視窗"
            : data.ButtonSentence
        );
        setIsRedirect(data.EnableRedirect);
        setLinkText(
          data.RedirectUrl === "" || data.RedirectUrl === undefined
            ? "https://www.cht.com.tw/home/consumer"
            : data.RedirectUrl
        );
        setWebPicture(data.EndPagePic);
        //console.log(data);
        setLoading(false);
      } catch (ex) {
        // alert("系統異常，請洽系統管理員");
        setLoading(false);
        throw ex;
      }
    }
    fetchData();
  }, [isEdit]); //
  // 保存上傳結束頁
  const saveOnClick = async () => {
    const reg = /^(http|https):\/\//;
    if (!linkText) {
      setIsLinkTextBlank(true);
      setIsLinkTextFormatWrong(false);
      return null;
    } else {
      setIsLinkTextBlank(false);
      if (reg.test(linkText)) {
        setIsLinkTextFormatWrong(false);
      } else {
        setIsLinkTextFormatWrong(true);
        return null;
      }
    }
    let data = {
      SurveyId: survey.id,
      EndPagePic: webPicture,
      EndPageStyle: JSON.stringify(content),
      ButtonSentence: buttonText,
      EnableRedirect: isRedirect,
      RedirectUrl: linkText,
    };
    try {
      let resp = await Survey.Actions.editEndPage(data);
      if (resp.code && resp.code == 206) {
        resp = await Survey.Actions.createEndPage(data);
      }
      setIsEdit(false);
      setEndPageData(data);
    } catch (ex) {
      // alert("系統異常，請洽系統管理員");
    }
  };

  const _contentDefault = () => {
    let data = {
      SurveyId: survey.id,
      EndPagePic: webPicture,
      EndPageStyle: initialValue,
      ButtonSentence: buttonText,
      EnableRedirect: isRedirect,
      RedirectUrl: linkText,
    };
    setEndPageData(data);
    setContent(initialValue);
  };

  const _setDefault = async () => {
    editorProps.resetContent();
    setIsEdit(false);
    setIsLinkTextBlank(false);
    setIsLinkTextFormatWrong(false);
  };

  const editorProps = useMemo(() => {
    // 確認內容是否空白
    const isContentBlank = () => {
      try {
        if (Array.isArray(editor.children)) {
          if (editor.children.length > 0) {
            let currNode = editor.children[0];
            while (currNode.children && currNode.children.length > 0) {
              currNode = currNode.children[0];
            }
            if (currNode.text && currNode.text.trim()) {
              return false;
            }
          }
        }
      } catch (err) {
        // console.log("isContentBlank error:", err);
      }
      return true;
    };
    // 設定內容
    const secureSetContent = content => {
      if (content && Array.isArray(content)) {
        if (content.length > 0) {
          setContent(content);
        } else {
          setContent(initialValue);
        }
      } else if (content && typeof content === "string") {
        setContent([
          {
            type: "paragraph",
            children: [{ text: content }],
          },
        ]);
      } else {
        setContent(initialValue);
      }
    };

    return {
      editor,
      setContent: secureSetContent,
      getContent: () => editor.children,
      isContentBlank: isContentBlank,
      resetSelection: () => {
        editor.selection = {
          anchor: { path: [0, 0, 0], offset: 0 },
          focus: { path: [0, 0, 0], offset: 0 },
        };
      },
      resetContent: text => {
        // we have to manually handle focus when force setting content,
        // see https://github.com/ianstormtaylor/slate/issues/3477
        editor.selection = {
          anchor: { path: [0, 0, 0], offset: 0 },
          focus: { path: [0, 0, 0], offset: 0 },
        };
        secureSetContent(text);
      },
    };
  }, [editor, content]);

  useEffect(() => {
    editorProps.setContent(content);
  }, [editorProps, content]);

  function isEmpty(content) {
    if (content.length === 1) {
      if (content[0].children.length === 1) {
        if (content[0].children[0].text === "") {
          return true;
        }
      }
    }
    return false;
  }

  if (loading) {
    return (
      <div
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "fixed",
          zIndex: "999999",
        }}
      >
        <Ant.Spin />
      </div>
    );
  }

  return (
    <Slate
      editor={editor}
      value={content}
      onChange={value => {
        if (isEmpty(value) && !isEmpty(content)) {
          ReactEditor.blur(editor);
        }
        setContent(value);
      }}
    >
      <Wrapper className={`questions-content`}>
        <PageHeader />
        <FinishContainer
          onDoubleClick={() => {
            if (!survey.audit && props.canEdit) {
              setIsEdit(true);
            }
          }}
        >
          {!isEdit ? (
            <div className="notEdit">
              <DefaultImg>
                {/* <div className="inner" style={{ backgroundImage: "url()" }}></div> */}
                {endPageData.EndPagePic ? (
                  <img src={endPageData.EndPagePic} style={{ width: "100%" }} />
                ) : (
                  <img
                    src="/images/EndPage_Web.png"
                    style={{ width: "100%" }}
                  />
                )}
              </DefaultImg>
              <DefaultText>
                {/* <h3></h3> */}
                {content && (
                  <TextWrapper>
                    <div className="content">
                      {!loading && (
                        // <RichTextEditor readOnly={true} />
                        <TextEndPageEdit
                          {...props}
                          editorProps={editorProps}
                          readOnly={true}
                        />
                      )}
                      {/* <TextEndPageEdit {...props} readOnly={true} /> */}
                    </div>
                  </TextWrapper>
                )}
              </DefaultText>
              <DefaultButton>
                <div>
                  {endPageData.ButtonSentence === "" ||
                  endPageData.ButtonSentence === undefined
                    ? "關閉問卷"
                    : endPageData.ButtonSentence}
                </div>
              </DefaultButton>
              {!survey.audit && props.canEdit && (
                <div className="actions">
                  <div className="wrapper">
                    <div
                      className="action-item"
                      onClick={() => setIsEdit(true)}
                    >
                      <SvgIcon.EditMode
                        color={Color.GreyBlack}
                        size={FontSize.largeTitle}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <>
              <EditWrapper>
                <ImgWrapper>
                  <div className="title">上方圖片</div>
                  <div className="imgWrapper-content">
                    <Ant.Upload
                      name="ending-page"
                      listType="picture-card"
                      className="imgWrapper-content-uploader"
                      showUploadList={false}
                      // beforeUpload={beforeUpload}
                      onChange={_handleChange.bind(null)}
                    >
                      {webPicture ? (
                        <img src={webPicture} style={{ width: "100%" }} />
                      ) : (
                        <img
                          src="/images/EndPage_Web.png"
                          style={{ width: "100%" }}
                        />
                      )}
                    </Ant.Upload>
                    <Ant.Button
                      className="btn outlineBtn"
                      style={{
                        width: 116,
                        height: 32,
                      }}
                      type="primary"
                      onClick={() => {
                        setWebPicture(null);
                      }}
                    >
                      <p>使用預設圖片</p>
                    </Ant.Button>
                  </div>

                  <WarnLabel style={{ marginLeft: 0, marginTop: 8 }}>
                    僅限插入一個圖片
                  </WarnLabel>
                </ImgWrapper>
                <TextWrapper>
                  <div className="title">文字</div>
                  <div className="content">
                    {!loading && (
                      <TextEndPageEdit
                        {...props}
                        editorProps={editorProps}
                        endingPage={true}
                        style={{ padding: 0 }}
                      />
                    )}
                    {/* <TextEndPageEdit
                      {...props}
                      editorProps={editorProps}
                      endingPage={true}
                      style={{ padding: 0 }}
                    /> */}
                  </div>
                </TextWrapper>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingLeft: 32,
                    paddingRight: 32,
                    paddingTop: 8,
                  }}
                >
                  <WarnLabel>
                    結束頁預設格式為思源黑體粗體藍色，字級 24 pt
                  </WarnLabel>
                  <div
                    style={{
                      width: 70,
                      textAlign: "center",
                      fontSize: `${FontSize.body}px`,
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "1.57em",
                      letterSpacing: "normal",
                      color: "#534ab3",
                      cursor: "pointer",
                    }}
                    onClick={_contentDefault}
                  >
                    返回預設值
                  </div>
                </div>

                <BtnWrapper>
                  <div className="title" style={{ marginBottom: "16px" }}>
                    結束按鈕
                  </div>
                  <div className="sub" style={{ marginBottom: "8px" }}>
                    按鈕文字
                  </div>
                  <ElementInput
                    width={"100%"}
                    size={"md"}
                    className={""}
                    placeholder={buttonText}
                    value={buttonText}
                    type={"text"}
                    style={{ marginBottom: "32px" }}
                    onChange={e => setButtonText(e.target.value)}
                  />
                  <Font.Body
                    className="text"
                    onClick={() => {
                      setIsRedirect(!isRedirect);
                    }}
                    style={{ marginBottom: "8px", cursor: "pointer" }}
                  >
                    {isRedirect ? (
                      <Icon.CheckBox
                        size={FontSize.head}
                        color={Color.Purple}
                        style={{ marginRight: 8 }}
                      />
                    ) : (
                      <Icon.CheckBoxOutlineBlank
                        size={FontSize.head}
                        color={Color.GreyBlack_25}
                        style={{ marginRight: 8 }}
                      />
                    )}
                    按鈕轉導連結
                  </Font.Body>
                  <ElementInput
                    width={"100%"}
                    size={"md"}
                    className={""}
                    placeholder={linkText}
                    value={linkText}
                    type={"text"}
                    disable={!isRedirect}
                    onChange={e => {
                      setLinkText(e.target.value);
                    }}
                    isError={isLinkTextBlank || isLinkTextFormatWrong}
                    isSuffix={isLinkTextBlank || isLinkTextFormatWrong}
                  />
                  {isLinkTextBlank ? (
                    <div
                      style={{ color: Color.Red, fontSize: 14, marginLeft: 4 }}
                    >
                      內容不能為空，請輸入有效網址
                    </div>
                  ) : null}
                  {isLinkTextFormatWrong ? (
                    <div
                      style={{ color: Color.Red, fontSize: 14, marginLeft: 4 }}
                    >
                      請輸入完整網址，開頭須為http:// 或 https://
                    </div>
                  ) : null}
                </BtnWrapper>
                <FooterBtns>
                  <Ant.Button
                    className="btn outlineBtn"
                    type="primary"
                    onClick={_setDefault}
                  >
                    <p>取消</p>
                  </Ant.Button>
                  <Ant.Button
                    className="btn defaultBtn"
                    type="primary"
                    onClick={() => saveOnClick()}
                  >
                    <p>儲存</p>
                  </Ant.Button>
                </FooterBtns>
              </EditWrapper>
            </>
          )}
        </FinishContainer>
      </Wrapper>
    </Slate>
  );
}

export function WarnLabel({ style = {}, children, ...props }) {
  return (
    <FlexRow style={style}>
      <Icon.InfoCircleOutlined color={Color.LightGold} />
      <Font.Small style={{ marginLeft: 6, color: Color.LightGold }}>
        {children}
      </Font.Small>
    </FlexRow>
  );
}

const FlexRow = ({ style = {}, ...props }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      ...style,
    }}
    {...props}
  >
    {props.children}
  </div>
);

const PreviewBox = styled.div``;

const ColorBox = styled.div`
  position: relative;
  overflow: hidden;
  border: 1px solid grey;
  background-color: ${props => props.color};
  height: 20px;
  width: 100px;

  & :before {
    ${props => (props.color === "transparent" ? "content: '';" : "")}
    width: 1px;
    display: block;
    height: 120px;
    background-color: #a6a6a6;
    position: absolute;
    transform: rotate(-79.5deg);
    transform-origin: top;
  }
`;

const ColorCircle = styled.div`
  position: relative;
  overflow: hidden;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border-radius: ${props => props.size / 2}px;
  border: 1px solid #a6a6a6;
  background-color: ${props => props.color};
  margin: 10px;

  & :before {
    ${props => (props.color === "transparent" ? "content: '';" : "")}
    width: 1px;
    display: block;
    height: 40px;
    background-color: #a6a6a6;
    position: absolute;
    transform: rotate(-45deg) translateX(13px);
  }
`;

const Wrapper = styled.div`
  position: relative;
  border-radius: 5px;
  margin: 12px auto;
  padding-bottom: 20px;
  background-color: #fff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);

  ${props => props.disable && "pointer-events: none;"}

  & .title {
    font-size: ${FontSize.largeTitle}px;
    color: #474747;
    padding: 24px 32px;
    margin-bottom: 16px;
  }

  & .header-wrapper {
    position: relative;
    width: 100%;
    height: 32;
    & .header {
      background-color: #dcdaef;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      color: #756ec2;
      font-size: ${FontSize.body}px;
      padding: 5px 24px;
    }
  }

  @keyframes show {
    from {
      transform: translateX(-10%);
      z-index: -1;
      opacity: 0;
    }
    to {
      transform: translateX(100%);
      z-index: 1;
      opacity: 1;
    }
  }
  .actions {
    display: block;
    pointer-events: none;
    opacity: 0;
    position: absolute;
    padding-left: 16px;
    right: 0px;
    top: 0px;
    /* transform: translateX(-15%); */
    transform: translateX(0%);
    transition: 200ms;
    z-index: -1;
    pointer-events: all;
    .wrapper {
      display: flex;
      flex-direction: column;
      background-color: #fff;
      padding: 20px 12px;
      border-radius: 4px;
      box-shadow: 0 9px 28px 8px rgba(0, 0, 0, 0.05),
        0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12);

      .action-item {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }
  }
  .btn {
    width: 60px;
    height: 32px;
    margin: 0;
    border-radius: 0;
    text-align: center;
    margin-right: 18px;
    &.defaultBtn {
      margin-right: 32px;
    }
    p {
      font-size: ${FontSize.body}px;
      line-height: 1.57;
      margin: 0 auto;
    }
  }
  .outlineBtn {
    border: solid 1px ${Color.MPurple_6_85};
    background-color: rgba(255, 255, 255, 0.4);
    p {
      color: ${Color.MPurple_6};
    }
    &:hover {
      border-color: ${Color.LightPurple_45};
      background-color: ${Color.MPurple_1_50};
      p {
        color: ${Color.LightPurple_45};
      }
    }
    &:active {
      border-color: ${Color.MPurple_6};
      background-color: rgba(186, 182, 224, 0.3);
      p {
        color: ${Color.MPurple_6};
      }
    }
    &:focus {
      /* -webkit-filter: blur(1px); */
      /* filter: blur(1px); */
      -webkit-box-shadow: 0 0 4px 2px ${Color.MPurple_1_50};
      -moz-box-shadow: 0 0 4px 2px ${Color.MPurple_1_50};
      box-shadow: 0 0 4px 2px ${Color.MPurple_1_50};
      border-color: ${Color.LightPurple_45};
      p {
        color: ${Color.MPurple_5};
      }
    }
    &[disabled] {
      border-color: ${Color.Black_15};
      background-color: ${Color.Black_10};
      p {
        color: ${Color.GreyBlack_25};
      }
    }
  }
  .defaultBtn {
    border: solid 1px ${Color.MPurple_6};
    background-color: ${Color.MPurple_6};
    p {
      color: #ffffff;
    }
    &:hover {
      border-color: ${Color.LightPurple_45};
      background-color: ${Color.LightPurple_45};
    }
    &:active {
      border-color: ${Color.MPurple_7};
      background-color: ${Color.MPurple_7};
    }
    &:focus {
      /* -webkit-filter: blur(1px); */
      /* filter: blur(1px); */
      -webkit-box-shadow: 0 0 4px 2px ${Color.LightPurple_45};
      -moz-box-shadow: 0 0 4px 2px ${Color.LightPurple_45};
      box-shadow: 0 0 4px 2px ${Color.LightPurple_45};
      border-color: ${Color.LightPurple_45};
      p {
        color: ${Color.MPurple_5};
      }
    }
    &[disabled] {
      border-color: ${Color.Black_15};
      background-color: ${Color.Black_15};
      p {
        color: ${Color.GreyBlack_25};
      }
    }
  }
`;

const FinishContainer = styled.div`
  position: relative;
  background-color: #ffffff;
  transition: background 300ms linear;
  & .notEdit {
    cursor: pointer;
    margin-bottom: 40px;
    &:hover {
      background-color: rgba(237, 236, 247, 0.45);
      .actions {
        z-index: 1;
        opacity: 1;
        transform: translateX(100%);
      }
    }
  }
`;

const DefaultImg = styled.div`
  display: block;
  width: 100%;
  height: auto;
  background-color: #fafafa;
  /* border: solid 1px #cccccc; */
  /* margin-bottom: 40px; */
  .inner {
    display: block;
    width: 800px;
    height: 227px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
`;
const DefaultText = styled.div`
  display: block;
  width: 100%;
  height: auto;
  margin-bottom: 60px;
  h3 {
    font-size: ${FontSize.largeTitle}px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.32px;
    text-align: center;
    color: #0075d3;
  }
`;

const DefaultButton = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 36px 36px 60px;
  @media screen and (max-width: 823px) {
    padding-top: 96px;
    padding-bottom: 204px;
  }
  div {
    min-width: 240px;
    height: 48px;
    box-shadow: 1px 1px 0.5px 0 rgba(0, 20, 37, 0.1);
    background-color: #209cff;
    border: solid 1px #209cff;
    font-size: ${FontSize.head}px;
    color: #ffffff;
    line-height: 1.5;
    letter-spacing: 2px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 823px) {
      min-width: 98px;
      height: 40px;
    }
    &:hover {
      background-color: #53b3ff;
      border: solid 1px #53b3ff;
      color: #ffffff;
    }
    &:active {
      background-color: #0083ec;
      border: solid 1px #0083ec;
      color: #ffffff;
    }
    &:focus {
      -webkit-box-shadow: 0 0 4px 2px #209cff;
      -moz-box-shadow: 0 0 4px 2px #209cff;
      box-shadow: 0 0 4px 2px #209cff;
      border: solid 1px #209cff;
      color: #ffffff;
    }
    &:disable {
      background-color: ${Color.Black_15};
      border: solid 1px ${Color.Black_15};
      color: ${Color.Black_10};
    }
  }
`;

const EditWrapper = styled.div`
  .title {
    font-size: ${FontSize.head}px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 24px;
    letter-spacing: normal;
    color: ${Color.Black_85};
    padding: 0;
  }
  .sub {
    font-size: ${FontSize.body}px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 22px;
    letter-spacing: normal;
    color: ${Color.Black_65};
  }
`;
const ImgWrapper = styled.div`
  padding: 16px 32px 0 32px;
  .imgWrapper-content {
    margin-bottom: 8px;
    margin-left: 32px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    &-uploader {
      width: 133px;
      height: 100px;
      margin-right: 16px;
      .ant-upload {
        width: 100%;
      }
    }
    /* &-button {
      font-size: ${FontSize.body}px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57em;
      letter-spacing: normal;
      color: #534ab3;
      border: solid 1px rgba(83, 74, 179, 0.85);
      background-color: rgba(255, 255, 255, 0.4);
    } */
  }
`;

const TextWrapper = styled.div`
  padding: 42px 36px 0 36px;
`;

const BtnWrapper = styled.div`
  padding: 36px 36px 0 36px;
`;

const FooterBtns = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 40px;
`;
