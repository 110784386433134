import React from "react";
import styled from "styled-components";
import * as Ant from "antd";
import * as SvgIcon from "./SvgIcon";
import Widget, { Color, FontSize } from "./Widget";
import clickOnce from "../Utils/ClickOnce";
import * as Survey from "../Contexts/SurveyContext";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { catText } from "../Utils/RichTextUtils";

const borderCss = "1px solid #e5e5e5";
const TypeDisplay = {
  jump: "跳題邏輯",
  prevent: "選項防呆",
  forceQuit: "強制結束",
  preventByQuestion: "跨題選項防呆",
};

function LogicRuleDisplay({ style = {}, ...props }) {
  function enableEditMode() {
    if (props.survey.audit) {
      Ant.Modal.warning({
        title: "不可編輯",
        content:
          "正在執行 CRM 陳核流程，已經不能編輯資料了。如果要重新編輯，須請主管退回簽呈。",
        okText: "確定",
        onOk: () => 0,
        icon: <ExclamationCircleFilled color={Color.LightGold} size={20} />,
      });
      return;
    }

    if (!props.canEdit) {
      Ant.Modal.warning({
        title: "不可刪除",
        content: "無編輯權限!",
        okText: "確定",
        onOk: () => 0,
        icon: <ExclamationCircleFilled color={Color.LightGold} size={20} />,
      });
      return;
    }

    props.setEditingMode(true);
    // props.setEditingIdx(props.idx);
    props.setEditingIdx(props.logic.id);
  }

  async function removeThisLogicRule() {
    if (props.survey.audit) {
      Ant.Modal.warning({
        title: "不可刪除",
        content:
          "正在執行 CRM 陳核流程，已經不能刪除資料了。如果一定要刪除，須請主管退回簽呈。",
        okText: "確定",
        onOk: () => 0,
        icon: <ExclamationCircleFilled color={Color.LightGold} size={20} />,
      });
      return;
    }

    if (!props.canEdit) {
      Ant.Modal.warning({
        title: "不可刪除",
        content: "無編輯權限!",
        okText: "確定",
        onOk: () => 0,
        icon: <ExclamationCircleFilled color={Color.LightGold} size={20} />,
      });
      return;
    }

    Ant.Modal.confirm({
      title: "確認要刪除此邏輯規則嗎？",
      content: "一旦刪除，此邏輯規則不可復原",
      okText: "取消",
      okType: "primary",
      onOk: () => 0,
      cancelButtonProps: { type: "ghost" },
      cancelText: "確認刪除",
      onCancel: async () => {
        await confirmDelete();
      },
      icon: <ExclamationCircleFilled color={Color.LightGold} size={20} />,
    });

    const confirmDelete = async () => {
      const hide = Ant.message.loading("刪除中", 0);
      try {
        // console.log('props.idx:', props.idx);
        // console.log('logicList', props.logicList);
        props.setEditingIdx(null);
        // return ''
        await Survey.Actions.removeLogicRule(props.survey.id, props.logic.id);
        props.setLogicList(
          await Survey.Actions.fetchLogicList(props.survey.id, props.survey)
        );
        props.setEditingIdx(null);
        //props.setUpdater(props.updater + 1);
      } catch (ex) {
        throw ex;
      }
      hide();
    };
  }

  function getQuestionDisplay(rule) {
    const question = props.survey.questions.find(q_ => q_.id === rule.question);
    if (question) {
      return `${question.getIdxDisplay()}. ${catText(question.content)}`;
    } else {
      return "---";
    }
  }

  function getConditionDisplay(rule) {
    if (rule.condition === "eq") {
      return "等於";
    } else if (rule.condition === "ueq") {
      return "不等於";
    } else if (rule.condition === "ans") {
      return "有回答";
    } else if (rule.condition === "uans") {
      return "沒回答";
    } else {
      return "---";
    }
  }

  function getOptionsDisplay(rule) {
    // console.log("getOptionsDisplay rule:", rule);
    const question = props.survey.questions.find(q_ => q_.id === rule.question);
    if (rule.options.length === 0 || rule.options[0] === undefined) {
      return "";
    }
    if (rule.options.length > 1) {
      return `${rule.options.length}個其中一個答案`;
    }

    const option = rule.options[0];
    return (
      `${option + 1}. ${question.options[option]?.value}` ||
      `選項 ${Number(option) + 1}`
    );
    //return `選項${option + 1}`;
  }

  function getActionDisplay(logic) {
    //console.log('getActionDisplay logic:', props.logic.constraint);
    if (logic.type === "jump") {
      const question = props.survey.questions.find(
        q_ => q_.id === logic.action
      );
      if (question) {
        return `${question.getIdxDisplay()}. ${catText(question.content)}`;
      }
    } else if (logic.type === "prevent") {
      if (logic.rules.length > 0) {
        const question = props.survey.questions.find(
          q_ => q_.id === logic.rules[0].question
        );
        if (question) {
          if (logic.action.length > 1) {
            return `其中${logic.action.length}個項目`;
          } else if (logic.action.length === 0) {
            return "---";
          }
          return (
            question.options[logic.action[0]]?.value ||
            `選項 ${Number(logic.action[0]) + 1}`
          );
        }
      }
    } else if (logic.type === "preventByQuestion") {
      const question = props.survey.questions.find(
        q_ => q_.id === logic.action
      );
      if (question) {
        return `${question.getIdxDisplay()}. ${catText(question.content)}`;
      } else {
        return "---";
      }
    }
    return "---";
  }

  // 顯示 tooltip 內容
  function getActionDisplayBlackOptionTooltip(logic) {
    const { blockOptionList } = logic;
    // 取得Question，需取用 options
    const question = props.survey.questions.find(q_ => q_.id === logic.action);

    // 如果有找到 Qusetion 或 blockOptionList 有值才往下處理
    if (question && blockOptionList && blockOptionList.length > 0) {
      if (blockOptionList.length > 1) {
        const { options } = question;
        const findOptionsIdx = [];
        // console.log('options', options, blockOptionList)
        blockOptionList.forEach(blockOption => {
          const findIndex = options.findIndex(
            option => option.id === blockOption
          );
          // console.log('findIndex', findIndex)
          if (findIndex != -1) {
            findOptionsIdx.push(findIndex + 1);
          }
        });
        // console.log('findOptionsIdx', findOptionsIdx)
        if (findOptionsIdx.length > 0) {
          return `第${findOptionsIdx.sort((a, b) => a - b).join("、")}項`;
        }
      }
    }
    return null;
  }

  // 組合顯示文字內容
  function getActionDisplayBlackOption(logic) {
    const { blockOptionList } = logic;
    // 取得Question，需取用 options
    const question = props.survey.questions.find(q_ => q_.id === logic.action);
    if (question && blockOptionList && blockOptionList.length > 0) {
      if (blockOptionList.length > 1) {
        return `其中${blockOptionList.length}個項目`;
      } else if (blockOptionList.length == 1) {
        const blockOptionId = blockOptionList[0];
        const idx = question.options.findIndex(
          option => option.id === blockOptionId
        );
        if (idx != -1)
          return question.options[idx]?.value || `選項 ${Number(idx) + 1}`;
      }
    }
    return "---";
  }

  return (
    // 當 needAdjust 時，顯示紅框
    <Wrapper style={{ ...style }} needAdjust={props.logic._data?.needAdjust}>
      <Widget.FlexRow style={{ alignItems: "stretch" }}>
        <div
          style={{
            borderRight: borderCss,
            width: 32,
            fontWeight: 500,
            textAlign: "center",
            padding: "14px 6px 0px",
          }}
        >
          {props.idx + 1 || "-"}
        </div>

        <div
          style={{
            borderRight: borderCss,
            width: 78,
            fontSize: `${FontSize.body}px`,
            fontWeight: "normal",
            textAlign: "center",
            padding: "14px 10px 0",
          }}
        >
          {TypeDisplay[props.logic.type]}
        </div>

        <Widget.FlexCol
          style={{
            borderRight: borderCss,
            borderRight: "none",
            padding: "16px 0 16px 16px",
            flex: 1,
          }}
        >
          {props.logic.rules.length === 0 && (
            <Widget.FlexRow style={{ alignItems: "flex-start" }}>
              <div style={{ marginRight: 10 }}>尚未設定規則</div>
            </Widget.FlexRow>
          )}

          {props.logic.rules.map((rule, idx) => (
            <Widget.FlexRow
              key={idx}
              style={{ alignItems: "flex-start", marginBottom: 13 }}
            >
              <div style={{ marginRight: 12 }}>如果</div>
              <div
                style={{
                  marginRight: 16,
                  width: 200,
                  color: Color.Purple,
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {getQuestionDisplay(rule)}
              </div>
              <div style={{ width: 42, marginRight: 22 }}>
                {getConditionDisplay(rule)}
              </div>
              {rule.condition === "eq" || rule.condition === "ueq" ? (
                <div
                  style={{
                    marginRight: 20,
                    width: 128,
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    color: Color.Purple,
                  }}
                >
                  <Ant.Tooltip
                    placement="bottom"
                    title={
                      rule.options.length > 1
                        ? `第 ${rule.options
                            .slice()
                            .sort((a, b) => a - b)
                            .map(o => o + 1)
                            .join("、")} 選項`
                        : ""
                    }
                  >
                    {rule.MatrixField}
                    {getOptionsDisplay(rule)}
                  </Ant.Tooltip>
                </div>
              ) : (
                ""
              )}
              <div style={{ width: 28 }}>{`${
                idx === props.logic.rules.length - 1
                  ? "  "
                  : props.logic.constraint === "all"
                  ? "而且"
                  : "或者"
              }`}</div>
            </Widget.FlexRow>
          ))}

          {props.logic.rules.length > 0 && props.logic.type === "jump" && (
            <Widget.FlexRow style={{ alignItems: "flex-start" }}>
              <div style={{ marginRight: 12 }}>然後</div>
              <div style={{ marginRight: 12 }}>跳題至</div>
              <div
                style={{
                  marginRight: 10,
                  width: 402,
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  color: Color.Purple,
                }}
              >
                {getActionDisplay(props.logic)}
              </div>
            </Widget.FlexRow>
          )}

          {props.logic.rules.length > 0 && props.logic.type === "prevent" && (
            <Widget.FlexRow style={{ alignItems: "flex-start" }}>
              <div style={{ marginRight: 10 }}>然後</div>
              <div style={{ marginRight: 10 }}>不可以選擇</div>
              <div
                style={{
                  marginRight: 10,
                  minWidth: 100,
                  maxWidth: 300,
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  color: Color.Purple,
                }}
              >
                <Ant.Tooltip
                  placement="bottom"
                  title={
                    props.logic.action.length > 1
                      ? `第 ${props.logic.action
                          .slice()
                          .sort((a, b) => a - b)
                          .map(o => o + 1)
                          .join("、")} 選項`
                      : ""
                  }
                >
                  {getActionDisplay(props.logic)}
                </Ant.Tooltip>
              </div>
            </Widget.FlexRow>
          )}

          {props.logic.rules.length > 0 && props.logic.type === "forceQuit" && (
            <Widget.FlexRow style={{ alignItems: "flex-start" }}>
              <div style={{ marginRight: 10 }}>然後</div>
              <div style={{ marginRight: 10 }}>強制結束問卷</div>
            </Widget.FlexRow>
          )}

          {props.logic.rules.length > 0 &&
            props.logic.type === "preventByQuestion" && (
              <Widget.FlexRow style={{ alignItems: "flex-start" }}>
                <div style={{ marginRight: 10 }}>然後</div>
                <div style={{ marginRight: 10 }}>不可以選擇題目</div>
                <div
                  style={{
                    marginRight: 10,
                    width: 200,
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    color: Color.Purple,
                  }}
                >
                  {getActionDisplay(props.logic)}
                </div>
                <div style={{ marginRight: 10 }}>的</div>
                <div
                  style={{
                    marginRight: 10,
                    minWidth: 100,
                    maxWidth: 300,
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    color: Color.Purple,
                  }}
                >
                  <Ant.Tooltip
                    placement="bottom"
                    title={getActionDisplayBlackOptionTooltip(props.logic)}
                  >
                    {getActionDisplayBlackOption(props.logic)}
                  </Ant.Tooltip>
                </div>
              </Widget.FlexRow>
            )}
        </Widget.FlexCol>
        {props.editingIdx === props.logic.id || props.editingIdx === null ? (
          <Widget.FlexRow>
            <div
              className="logic-action-item"
              style={{ marginRight: 24 }}
              onClick={clickOnce(enableEditMode)}
            >
              <SvgIcon.EditMode
                color={Color.GreyBlack}
                size={FontSize.largeTitle}
              />
            </div>
            <div
              className="logic-action-item"
              style={{ marginRight: 24 }}
              onClick={clickOnce(removeThisLogicRule)}
            >
              <SvgIcon.Delete
                color={Color.GreyBlack}
                size={FontSize.largeTitle}
              />
            </div>
          </Widget.FlexRow>
        ) : (
          ""
        )}
      </Widget.FlexRow>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  border-top: ${props => (props.needAdjust ? "1px solid red" : borderCss)};
  border-bottom: ${props => (props.needAdjust ? "1px solid red" : borderCss)};
  border-left: ${props => (props.needAdjust ? "1px solid red" : borderCss)};
  border-right: ${props => (props.needAdjust ? "1px solid red" : borderCss)};
  overflow: hidden;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
  cursor: pointer;
  transition: all 0.2s linear;

  & .logic-action-item {
    opacity: 0;
    transition: all 0.2s linear;
  }
  &:hover {
    background-color: #f6f5ff;
    & .logic-action-item {
      opacity: 1;
    }
  }
`;

export default LogicRuleDisplay;
