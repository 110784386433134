import React from "react";
import styled from "styled-components";
import Widget, { Color, FontSize } from "../Components/Widget";

const BarHeight = 32;
const RowHeight = BarHeight + 24;
const LeftContentWidth = 200;
const RightContentWidth = 700;

function BarChart(props) {
  const { rows, gridLines } = props;

  return (
    <ChartWrapper rows={rows}>
      <Widget.FlexRow>
        <div className="left">
          {rows.map((row, idx) => {
            return (
              <LeftRow key={idx}>
                <div className="label">{`${idx + 1}. ${row.text}`}</div>
              </LeftRow>
            );
          })}
        </div>

        <div className="right">
          {gridLines.map((label, idx) => (
            <GridLine key={idx}>
              {idx === 0 && <ZeroLabel>0</ZeroLabel>}
              <ColLabel>{label}</ColLabel>
            </GridLine>
          ))}
          {rows.map((row, idx) => {
            return (
              <RightRow key={idx} idx={idx}>
                <Bar
                  width={(
                    (row.value[0] * 100) /
                    gridLines[gridLines.length - 1]
                  ).toFixed(1)}
                >
                  <div style={{ marginLeft: 10 }}>{`${row.value[0]}`}</div>
                </Bar>
                <div
                  style={{ marginLeft: 10, color: Color.Purple }}
                >{`${row.value[1]}%`}</div>
              </RightRow>
            );
          })}
        </div>
      </Widget.FlexRow>
    </ChartWrapper>
  );
}

const LeftRow = styled.div`
  height: ${RowHeight}px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 16px;

  & > .label {
    font-size: ${FontSize.body}px;
    color: ${Color.GreyBlack};
    width: 100%;
    text-align: right;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const RightRow = styled.div`
  position: absolute;
  left: 0;
  top: ${props => props.idx * RowHeight}px;
  width: 100%;
  height: ${RowHeight}px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const Bar = styled.div`
  height: ${BarHeight}px;
  min-width: ${props => props.width}%;
  width: ${props => props.width}%;
  background-color: #7c76c5;
  color: white;
  display: flex;
  align-items: center;
`;

const GridLine = styled.div`
  width: ${RightContentWidth / 5 - 1}px;
  border-right: 1px solid #ccc;
  position: relative;
`;

const ZeroLabel = styled.div`
  position: absolute;
  left: -50px;
  bottom: -30px;
  width: 100px;
  text-align: center;
`;

const ColLabel = styled.div`
  position: absolute;
  right: -50px;
  bottom: -30px;
  width: 100px;
  text-align: center;
`;

const ChartWrapper = styled.div`
  width: ${LeftContentWidth + RightContentWidth + 40}px;
  padding: 40px 32px;

  & .left {
    width: ${LeftContentWidth - 1}px;
    height: ${props => props.rows.length * RowHeight}px;
    border-right: 1px solid #ccc;
  }

  & .right {
    width: ${RightContentWidth}px;
    height: ${props => props.rows.length * RowHeight}px;
    position: relative;
    display: flex;
    align-items: stretch;
  }
`;

export default BarChart;
