import React from "react";
import styled from "styled-components";
import { Color, FontSize } from "../Components/Widget";

function MatrixStatisticsTable(props) {
  let { rows, cols, colsObj } = props;

  return (
    <Wrapper>
      <div className="header">
        <div className="label"></div>
        {rows.map((row, idx) => (
          <div className="item" key={idx}>
            {row}
          </div>
        ))}
      </div>

      <div className="cols-container">
        {cols.map((col, idx) => {
          return (
            <div className="col" key={idx}>
              <div className="label">{`${idx + 1}. ${col}`}</div>
              {colsObj[col].map((num, idx) => (
                <div className="item" key={idx}>{`${num[0]} (${num[1]}%)`}</div>
              ))}
            </div>
          );
        })}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  --label-width: 200px;
  font-size: ${FontSize.body}px;
  color: ${Color.GreyBlack};
  padding: 40px 0px 40px 64px;

  & > .header {
    padding: 10px 0px;
    display: flex;
    align-items: center;
    background-color: ${Color.GreyBlack_04};

    & > .label {
      flex-basis: var(--label-width);
      flex-shrink: 0;
    }

    & > .item {
      flex: 1;
      text-align: center;
    }
  }

  & > .cols-container {
    & > .col {
      display: flex;
      align-items: center;
      border-bottom: 1px solid rgba(187, 187, 203, 0.85);

      & > .label {
        flex-basis: var(--label-width);
        flex-shrink: 0;
        padding: 10px 8px;
      }

      & > .item {
        flex: 1;
        text-align: center;
      }
    }
  }
`;

export default MatrixStatisticsTable;
