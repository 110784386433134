import React, { useState } from "react";
import styled from "styled-components";
import * as Ant from "antd";
import Widget, { Font, Color, FontSize } from "./Widget";
import * as Icon from "./Icon";
import * as Survey from "../Contexts/SurveyContext";
import { ExclamationCircleFilled } from "@ant-design/icons";
import moment from "moment";
import _ from "lodash";
import { SURVEY_TYPE } from "../constants";

const StatusDisplay = {
  editing: "編輯中",
};

const MethodDisplay = {
  1: "網頁收集",
  2: "QR Code",
};

const CollectionList = ({ collections, setEditingIdx, ...props }) => {
  // 此頁面針對CDP類型問卷，不可刪除問卷登入方式
  const surveyType = _.get(props, "survey.type", "");

  const columns = [
    {
      title: "收集方式",
      key: "method",
      render: (_, { method }) => {
        return <Font.Body>{MethodDisplay[method]}</Font.Body>;
      },
    },
    {
      title: "建立時間",
      key: "created",
      render: (_, { created }) => (
        <Font.Body>{moment(created).format("YYYY/MM/DD HH:mm")}</Font.Body>
      ),
    },
    {
      title: "最後修改時間",
      key: "updated",
      render: (_, { updated }) => (
        <Font.Body>{moment(updated).format("YYYY/MM/DD HH:mm")}</Font.Body>
      ),
    },
    {
      title: "收集數量上限",
      key: "upperBound",
      align: "right",
      render: (_, { upperBound }) => (
        <Font.Body>{`${upperBound ? upperBound : "無上限"}`}</Font.Body>
      ),
    },
    {
      title: "填寫次數",
      key: "respCnt",
      align: "left",
      render: (_, { respCnt }) => (
        <Font.Body>{`${respCnt === null ? "-" : respCnt}`}</Font.Body>
      ),
    },
    {
      title: () => <div style={{ color: Color.MPurple_4 }}>編輯</div>,
      key: "edit",
      width: 36,
      align: "center",
      render: (_, record, index) => (
        <div
          style={{ width: 36, cursor: "pointer" }}
          onClick={() => {
            // if (props.survey.audit) {
            //   Ant.Modal.warning({
            //     title: "不可編輯",
            //     content:
            //       "正在執行 CRM 陳核流程，已經不能編輯資料了。如果要重新編輯，須請主管退回簽呈。",
            //     okText: "確定",
            //     onOk: () => 0,
            //     icon: (
            //       <ExclamationCircleFilled color={Color.LightGold} size={20} />
            //     ),
            //   });
            //   return;
            // }

            Survey.Actions.setDeleteCollectionOnCancel(false);
            setEditingIdx(index);
          }}
        >
          <Icon.Edit size={FontSize.head} color={Color.MPurple_4} />
        </div>
      ),
    },
    {
      title: () => <div style={{ color: Color.MPurple_4 }}>刪除</div>,
      key: "remove",
      width: 36,
      align: "center",
      render: (_, record) => (
        <div
          style={{ width: 36, cursor: "pointer" }}
          // CDP類型問卷無法在此介面修改問卷登入方式
          disabled={surveyType === SURVEY_TYPE.CDP}
          onClick={() => {
            if (surveyType === SURVEY_TYPE.CDP) {
              Ant.Modal.warning({
                title: "不可刪除",
                content: "CDP問卷無法刪除收集方式!",
                okText: "確定",
                onOk: () => 0,
                icon: (
                  <ExclamationCircleFilled color={Color.LightGold} size={20} />
                ),
              });
              return;
            }

            if (!props.canEdit) {
              Ant.Modal.warning({
                title: "不可刪除",
                content: "無刪除權限!",
                okText: "確定",
                onOk: () => 0,
                icon: (
                  <ExclamationCircleFilled color={Color.LightGold} size={20} />
                ),
              });
              return;
            }
            deleteCollection(record.id, record.method, props.survey.audit);
          }}
        >
          <Icon.Delete size={FontSize.head} color={Color.MPurple_4} />
        </div>
      ),
    },
  ];

  function deleteCollection(collectionId, provideType, isAudit) {
    if (isAudit) {
      Ant.Modal.warning({
        title: "不可刪除",
        content:
          "正在執行 CRM 陳核流程，已經不能刪除資料了。如果要重新編輯，須請主管退回簽呈。",
        okText: "確定",
        onOk: () => 0,
        icon: <ExclamationCircleFilled color={Color.LightGold} size={20} />,
      });
      return;
    }
    Ant.Modal.confirm({
      title: "確認要刪除此收集方式嗎？",
      content: "一旦刪除，連同測試網址的資料也會一併消失",
      okText: "取消",
      okType: "primary",
      onOk: () => 0,
      cancelButtonProps: {
        type: "ghost",
        style: { border: `1px solid ${Color.Purple}`, color: Color.Purple },
      },
      cancelText: "確認刪除",
      onCancel: async () => {
        const hide = Ant.message.loading("刪除中...", 0);
        try {
          await Survey.Actions.deleteCollection(props.survey.id, provideType);
          // 新增刪除交叉選項設定
          await Survey.Actions.deleteLimitData(props.survey.id, provideType);
          props.setUpdater(props.updater + 1);
        } catch (ex) {
          // console.warn(ex);
        }
        hide();
      },
      icon: <ExclamationCircleFilled color={Color.LightGold} size={20} />,
    });
  }

  return (
    <AntTableCssOverwriteWrapper>
      <Ant.Table
        dataSource={collections}
        columns={columns}
        pagination={false}
      />
    </AntTableCssOverwriteWrapper>
  );
};

const AntTableCssOverwriteWrapper = styled.div`
  & thead.ant-table-thead > tr > th {
    background-color: #eff1f7;
    color: ${Color.GreyBlack_45};
  }
`;

export default CollectionList;
