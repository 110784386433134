import React, { Component } from "react";
import styled from "styled-components";
import ActionCreator from "../ActionCreator";
import Selectors from "../Selectors";
import { connect } from "react-redux";
import * as Icon from "./Icon";
import STEP from "../Domain/SurveyStep";
import ContentConfig from "./SurveyDetailContent.config";
import ContentCollect from "./SurveyDetailContent.collect";
import ContentLogic from "./SurveyDetailContent.logic";

class SurveyDetailContent extends Component {
  render() {
    let { step = "" } = this.props;
    if (!this.props.survey) {
      return <div></div>;
    }
    // 問卷設計步驟 config.key 問卷設置, logic.key 邏輯設置, collect.key 問卷收集方式設置
    switch (step) {
      case STEP.CONFIG.key:
        return <ContentConfig {...this.props} />;
      case STEP.LOGIC.key:
        return <ContentLogic {...this.props} />;
      case STEP.COLLECT.key:
        return <ContentCollect {...this.props} />;
      // case STEP.RESULT.key: // note: we don't render statistics content in this comp
      //   return null;
      default:
        return null;
    }
  }
}

export default SurveyDetailContent;
