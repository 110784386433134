import React, { useEffect, useState } from "react";
import styled from "styled-components";
import * as Ant from "antd";
import { ExclamationCircleFilled, PlusOutlined } from "@ant-design/icons";

import { catText } from "../Utils/RichTextUtils";
import { LogicRuleMapToApi } from "../Utils/LogicRuleMap";
import clickOnce from "../Utils/ClickOnce";

import Widget, { Font, Color, FontSize } from "./Widget";
import * as Icon from "./Icon";
import * as SvgIcon from "./SvgIcon";
import * as Survey from "../Contexts/SurveyContext";

const LogicTypes = {
  jump: {
    key: "jump",
    label: "跳題邏輯",
    hint: "此功能僅限「單選題」、「多選題」可以設定",
    maxMatch: 5,
    thenLabel: "跳題至",
    validQuestionTypes: ["SINGLE_CHOICE", "MULTIPLE_CHOICE"],
  },
  prevent: {
    key: "prevent",
    label: "選項防呆",
    hint: "此功能僅限「多選題」可以設定、當條件滿足時不可選的選項會自動灰底",
    maxMatch: 2,
    thenLabel: "不可以選擇",
    validQuestionTypes: ["MULTIPLE_CHOICE"],
  },
  forceQuit: {
    key: "forceQuit",
    label: "強制結束問卷",
    hint: "此功能僅限「單選題」、「多選題」、「矩陣題」可以設定",
    maxMatch: 2,
    thenLabel: "強制結束問卷",
    validQuestionTypes: ["SINGLE_CHOICE", "MULTIPLE_CHOICE", "MATRIX"],
  },
  preventByQuestion: {
    key: "preventByQuestion",
    label: "跨題選項防呆",
    hint: "此功能僅限「單選題」、「複選題」、「矩陣題」可以設定",
    maxMatch: 5,
    thenLabel: "不可以選擇",
    validQuestionTypes: ["SINGLE_CHOICE", "MULTIPLE_CHOICE", "MATRIX"],
  },
};

function displayOption(opt, idx) {
  if (!opt.type || opt.type === "default" || opt.type === "others") {
    return `${idx + 1} ${opt.value}`;
  }
  return `選項${idx + 1}`;
}

function displayJumpQuestion(q) {
  return (
    <>
      <span
        style={{ color: Color.Purple, marginRight: 5 }}
      >{`${q.getIdxDisplay()}.`}</span>
      <OptionText className="option-text">{`${catText(q.content) ||
        ""}`}</OptionText>
    </>
  );
}

function displayMatrixField(opt, idx) {
  if (!opt.type || opt.type === "default") {
    return opt;
  }
  return `選項${idx + 1}`;
}

function LogicRule({ style = {}, ...props }) {
  const { idx, logic } = props;
  const logicType = LogicTypes[logic.type];
  const [unsavedLogic, setUnsavedLogic] = useState(logic._data);
  const [clickable, setClickable] = useState(true);

  function addRule() {
    if (logicType.maxMatch !== 0) {
      if (unsavedLogic.rules.length >= logicType.maxMatch) {
        Ant.Modal.warning({
          title: "不可新增",
          content: `最多${logicType.maxMatch}個條件`,
          okText: "確定",
          icon: <ExclamationCircleFilled color={Color.LightGold} size={20} />,
        });
        return;
      }
    }

    const rules = [
      ...unsavedLogic.rules,
      {
        question: unsavedLogic.rules[0].question || null,
        condition: undefined,
        options: [],
      },
    ];
    setUnsavedLogic({
      ...unsavedLogic,
      rules,
    });
  }

  function removeRule(idx) {
    if (unsavedLogic.rules.length === 1) {
      Ant.Modal.warning({
        title: "不可刪除",
        content: `最少1個條件`,
        okText: "確定",
        icon: <ExclamationCircleFilled color={Color.LightGold} size={20} />,
      });
      return;
    }
    const rules = [...unsavedLogic.rules];
    rules.splice(idx, 1);
    setUnsavedLogic({
      ...unsavedLogic,
      rules,
    });
  }

  function updateRule(idx, rule) {
    const rules = JSON.parse(JSON.stringify([...unsavedLogic.rules]));
    let action = null;
    if (unsavedLogic.type === "prevent") {
      if (idx === 0 && rules.length > 1) {
        rules[1].question = rule.question;
        rules[1].options = [];
      }
      rules.map((item, index) => {
        if (index !== idx) {
          rules[index].question = rule.question;
        }
      });
      if (!action) {
        action = unsavedLogic.action.filter(
          item => rule.options.indexOf(item) === -1
        );
      }
      if (rule.question !== unsavedLogic.rules[0].question) {
        action = [];
      }
    }
    rules[idx] = rule;
    setUnsavedLogic({
      ...unsavedLogic,
      rules,
      action,
    });
  }

  function updateAction(action) {
    setUnsavedLogic(prevState => {
      return {
        ...prevState,
        action,
      };
    });
  }

  // 新增跨題選項限制 設定之 選項
  function updateBlockOptionList(blockOptionList) {
    setUnsavedLogic(prevState => {
      return {
        ...prevState,
        blockOptionList,
      };
    });
  }

  const confirmDelete = async isDeleteOnCancel => {
    const hide = Ant.message.loading(isDeleteOnCancel ? "更新中" : "刪除中", 0);
    try {
      await Survey.Actions.removeLogicRule(props.survey.id, props.logic.id);
      props.setEditingIdx(null);
      props.setUpdater(props.updater + 1);
    } catch (ex) {
      throw ex;
    }
    hide();
  };

  async function removeThisLogicRule() {
    Ant.Modal.confirm({
      title: "確認要刪除此邏輯規則嗎？",
      content: "一旦刪除，此邏輯規則不可復原",
      okText: "取消",
      okType: "primary",
      onOk: () => 0,
      cancelButtonProps: { type: "ghost" },
      cancelText: "確認刪除",
      onCancel: async () => {
        await confirmDelete();
      },
      icon: <ExclamationCircleFilled color={Color.LightGold} size={20} />,
    });
  }

  async function cancelThisLogicRule() {
    const hide = Ant.message.loading("更新中", 0);
    try {
      if (Survey.Actions.shouldDeleteLogicOnCancel()) {
        if (!props.editingMode) {
          const nextList = [...props.logicList];
          nextList.splice(nextList.length - 1, 1);
          props.setLogicList(nextList);
        }
      }
    } catch (ex) {
      throw ex;
    }
    props.setEditingIdx(null);
    props.setEditingMode(false);
    await hide();
  }

  async function updateThisLogicRule() {
    setClickable(false);
    const hide = Ant.message.loading("更新中", 0);
    try {
      let data = LogicRuleMapToApi(props.survey, unsavedLogic);
      if (props.editingMode) {
        data.LogicList[0].LogicId = unsavedLogic.id;
        props.setEditingMode(false);
        await Survey.Actions.updateLogicRule(data);
      } else {
        await Survey.Actions.insertLogicRule(data);
        props.setEditingMode(false);
      }
      props.setLogicList(
        await Survey.Actions.fetchLogicList(props.survey.id, props.survey)
      );
      props.setEditingIdx(null);
    } catch (ex) {
      setClickable(true);
      throw ex;
    }
    setClickable(true);
    hide();
  }

  return (
    <Wrapper style={style}>
      <Widget.FlexRow
        style={{
          backgroundColor: Color.LightPurple,
          paddingTop: 15,
          paddingLeft: 48,
          paddingBottom: 15,
          paddingRight: 35,
        }}
      >
        <Font.Body style={{ flex: 1 }}>{`規則 ${idx + 1} - ${
          logicType.label
        }`}</Font.Body>
        <div
          style={{ cursor: "pointer", width: 24, height: 24 }}
          onClick={cancelThisLogicRule}
        >
          <SvgIcon.Close />
        </div>
      </Widget.FlexRow>
      <Widget.FlexRow
        style={{
          backgroundColor: "rgba(15, 14, 35, 0.7)",
          padding: "15px 32px",
        }}
      >
        <Icon.InfoCircleOutlined
          color="white"
          css="width: 20px; height: 20px;"
          className="svg-100"
        />
        <Font.Body style={{ flex: 1, marginLeft: 15, color: "white" }}>
          {logicType.hint}
        </Font.Body>
      </Widget.FlexRow>
      <div className="content-wrapper">
        <Widget.FlexRow style={{ marginBottom: 16, alignItems: "center" }}>
          <Font.Head
            style={{
              width: 56,
              textAlign: "center",
              padding: "4px 8px",
              backgroundColor: Color.Purple,
              color: "white",
            }}
          >
            如果
          </Font.Head>
          <Font.Body style={{ marginLeft: 16, width: 28 }}>符合</Font.Body>
          <Ant.Select
            className="select-xs-padding"
            size="small"
            style={{ marginLeft: 16, width: 64 }}
            onChange={v => {
              setUnsavedLogic({ ...unsavedLogic, constraint: v });
            }}
            value={unsavedLogic.constraint}
          >
            <Ant.Select.Option value="any">任何</Ant.Select.Option>
            <Ant.Select.Option value="all">全部</Ant.Select.Option>
          </Ant.Select>
          <Font.Body style={{ marginLeft: 16 }}>以下的條件</Font.Body>
        </Widget.FlexRow>
        {unsavedLogic.rules.map((rule, idx) => (
          <Rule
            key={idx}
            rule={rule}
            idx={idx}
            ruleIndex={idx}
            updateRule={updateRule.bind(null, idx)}
            removeRule={removeRule}
            first={idx === 0}
            last={idx === unsavedLogic.rules.length - 1}
            unsavedLogic={unsavedLogic}
            logicType={logicType}
            {...props}
          />
        ))}
        <Widget.FlexRow style={{ marginTop: -3, marginBottom: 25 }}>
          <Ant.Button
            type="link"
            size="small"
            icon={<PlusOutlined size={14} />}
            onClick={addRule}
            style={{
              width: 102,
            }}
          >
            新增條件
          </Ant.Button>

          {logicType.maxMatch > 0 && (
            <Widget.FlexRow style={{ marginLeft: 16 }}>
              <Icon.InfoCircleOutlined color={Color.LightGold} />
              <Font.Body style={{ marginLeft: 8, color: Color.LightGold }}>
                {`最多僅能設定${logicType.maxMatch}個條件`}
              </Font.Body>
            </Widget.FlexRow>
          )}
        </Widget.FlexRow>
        <Widget.FlexRow style={{ marginBottom: 10, alignItems: "center" }}>
          <Font.Body
            style={{
              width: 56,
              height: 30,
              lineHeight: "30px",
              textAlign: "center",
              backgroundColor: Color.Purple,
              color: "white",
            }}
          >
            然後
          </Font.Body>
          <Font.Body style={{ marginLeft: 16, color: "rgba(0, 0, 0, 0.65)" }}>
            {logicType.thenLabel}
          </Font.Body>
        </Widget.FlexRow>
        {unsavedLogic.type !== "forceQuit" && (
          <Action
            unsavedLogic={unsavedLogic}
            action={unsavedLogic.action}
            updateAction={updateAction}
            updateBlockOptionList={updateBlockOptionList}
            {...props}
          />
        )}
        <Widget.FlexRow style={{ justifyContent: "flex-end", marginTop: 32 }}>
          <Ant.Button
            type="outline"
            style={{ marginRight: 24 }}
            onClick={cancelThisLogicRule}
          >
            取消
          </Ant.Button>
          <Ant.Button
            type="primary"
            onClick={clickable ? clickOnce(updateThisLogicRule) : ""}
          >
            儲存
          </Ant.Button>
        </Widget.FlexRow>
      </div>
    </Wrapper>
  );
}

function Rule(props) {
  const { rule, updateRule, removeRule, unsavedLogic, logicType } = props;

  const _getAvailableQuestions = () => {
    let qs = props.survey.questions.filter((q, idx) => {
      if (logicType.key === "jump") {
        return (
          // note: 母題、子題皆不可設定跳題邏輯
          q.options.every(o => !o.connectTo) &&
          !props.survey.getParentQuestionByQuestionId(q.id) &&
          logicType.validQuestionTypes.indexOf(q.type) > -1
        );
      }
      return logicType.validQuestionTypes.indexOf(q.type) > -1;
    });
    return qs;
  };

  const questions = _getAvailableQuestions();
  const selectedQuestion = props.survey.questions.find(
    q => q.id === rule.question
  );

  const availabeContraintChoice =
    selectedQuestion?.type === "MATRIX" || unsavedLogic?.type === "prevent"
      ? [
          { value: "eq", display: "等於" },
          { value: "ueq", display: "不等於" },
        ]
      : [
          { value: "eq", display: "等於" },
          { value: "ueq", display: "不等於" },
          { value: "ans", display: "有回答" },
          { value: "uans", display: "沒回答" },
        ];

  return (
    <RuleWrapper>
      <QuestionWrapper>
        {props.first && (
          <Font.Small
            style={{
              color: "rgba(0, 0, 0, 0.65)",
              height: 20,
              marginBottom: 4,
            }}
          >
            題目
          </Font.Small>
        )}
        <Ant.Select
          allowClear
          className="pl-16 question-select"
          style={{ marginLeft: 8, left: -8, width: 280 }}
          size="large"
          value={selectedQuestion?.id}
          placeholder="題目"
          disabled={
            unsavedLogic.type === "prevent" && !props.first ? true : false
          }
          onChange={value => {
            updateRule({
              ...rule,
              question: value,
              condition: "eq",
              options: [],
              MatrixField: undefined,
            });
          }}
        >
          {questions.map((q, idx) => {
            return (
              <Ant.Select.Option key={q.id} value={q.id}>
                <span
                  style={{ color: Color.Purple, marginRight: 5 }}
                >{`${q.getIdxDisplay()}.`}</span>
                <OptionText className="option-text">{`${catText(q.content) ||
                  ""}`}</OptionText>
              </Ant.Select.Option>
            );
          })}
        </Ant.Select>
      </QuestionWrapper>
      <ConditionWrapper>
        {props.first && (
          <Font.Small
            style={{
              color: "rgba(0, 0, 0, 0.65)",
              height: 20,
              marginBottom: 4,
            }}
          >
            條件
          </Font.Small>
        )}
        <Ant.Select
          className="question-select"
          style={{ marginLeft: 8, left: -8, width: 112 }}
          size="large"
          value={rule.condition}
          placeholder="條件"
          onChange={value => updateRule({ ...rule, condition: value })}
        >
          {availabeContraintChoice.map((c, idx) => (
            <Ant.Select.Option key={idx} value={c.value}>
              {c.display}
            </Ant.Select.Option>
          ))}
        </Ant.Select>
      </ConditionWrapper>
      {["ans", "uans"].indexOf(rule.condition) === -1 && (
        <AnswerWrapper>
          {props.first && (
            <Font.Small
              style={{
                color: "rgba(0, 0, 0, 0.65)",
                height: 20,
                marginBottom: 4,
              }}
            >
              選項
            </Font.Small>
          )}
          <MatrixRowOption className="matrix-row-option">
            {selectedQuestion?.type === "MATRIX" && (
              <Ant.Select
                className="matrix-select"
                allowClear
                value={rule.MatrixField}
                placeholder="欄位"
                onChange={value => updateRule({ ...rule, MatrixField: value })}
              >
                {(selectedQuestion?.rows || []).map((opt, idx) => (
                  <Ant.Select.Option key={idx} value={opt}>
                    <OptionText className="option-text">
                      {displayMatrixField(opt, idx)}
                    </OptionText>
                  </Ant.Select.Option>
                ))}
              </Ant.Select>
            )}
            <Ant.Select
              allowClear
              showArrow
              className="input-items"
              style={{
                flex: selectedQuestion?.type === "MATRIX" ? "0 1 49%" : 1,
              }}
              size="large"
              value={rule.options}
              placeholder="選項"
              mode="multiple"
              optionLabelProp="label"
              onChange={value => updateRule({ ...rule, options: value })}
            >
              {(selectedQuestion?.options || []).map((opt, idx) => (
                <Ant.Select.Option key={idx} value={idx} label={`${idx + 1}`}>
                  <OptionText className="option-text">
                    {displayOption(opt, idx) || ""}
                  </OptionText>
                </Ant.Select.Option>
              ))}
            </Ant.Select>
          </MatrixRowOption>
        </AnswerWrapper>
      )}
      <IconWrapper style={{ marginTop: props.first ? "24px" : "0px" }}>
        <Icon.Remove
          size={FontSize.largeTitle}
          color={Color.GreyBlack}
          onClick={() => removeRule(props.ruleIndex)}
        />
      </IconWrapper>
    </RuleWrapper>
  );
}

function Action(props) {
  let { unsavedLogic } = props;
  const [options, setOptions] = useState([]);

  useEffect(() => {
    // 搜尋Question連動Options
    if (unsavedLogic.action) {
      gneOptions(unsavedLogic.action);
    }
  }, []);

  const jumpQuestions = props.survey.questions
    .filter(q => q.isRealQuestion())
    .filter(q => {
      let maxiumInRuleQuestionIdx = 0;
      unsavedLogic.rules.forEach(r => {
        let found = props.survey.questions.find(q => q.id == r.question);
        if (found && found.idx > maxiumInRuleQuestionIdx) {
          maxiumInRuleQuestionIdx = found.idx;
        }
      });
      return (
        maxiumInRuleQuestionIdx < q.idx &&
        q.options.every(o => !o.connectTo) &&
        !props.survey.getParentQuestionByQuestionId(q.id)
      );
    });

  function _getPreventOptions(rules) {
    if (rules.length === 0) {
      return [];
    }
    let alreadyInConstraintOptions = [];
    let targetQuestionId = rules[0].question;
    // note: only allow for the same question
    let found = props.survey.questions.find(q => q.id === targetQuestionId);
    if (!found) {
      return [];
    }
    rules
      .filter(r => r.question === targetQuestionId)
      .forEach(r => {
        let _temp = found.options
          .map((o, idx) => ({ ...o, idx }))
          .filter((o, idx) => r.options.indexOf(idx) !== -1);
        _temp.forEach(o => {
          if (alreadyInConstraintOptions.indexOf(o.idx) === -1) {
            alreadyInConstraintOptions.push(o.idx);
          }
        });
      });
    return found.options
      .map((o, idx) => ({ ...o, idx }))
      .filter((o, idx) => alreadyInConstraintOptions.indexOf(idx) === -1);
  }

  // 跨題選項防呆 - 題目，限制單選、多選， Copy by jumpQuestions
  function preventByQuestion() {
    const preventQuestion = props.survey.questions
      .filter(q => q.type == "SINGLE_CHOICE" || q.type == "MULTIPLE_CHOICE")
      .filter(q => {
        let maxiumInRuleQuestionIdx = 0;
        unsavedLogic.rules.forEach(r => {
          let found = props.survey.questions.find(q => q.id == r.question);
          if (found && found.idx > maxiumInRuleQuestionIdx) {
            maxiumInRuleQuestionIdx = found.idx;
          }
        });
        return (
          maxiumInRuleQuestionIdx < q.idx &&
          q.options.every(o => !o.connectTo) &&
          !props.survey.getParentQuestionByQuestionId(q.id)
        );
      });
    return preventQuestion || [];
  }

  function gneOptions(value) {
    let gneOptions = [];
    if (value) {
      const question = props.survey.questions.find(
        question => question.id === value
      );
      if (question) {
        gneOptions = question.options;
      }
    }
    setOptions(gneOptions);
  }

  return (
    <Widget.FlexRow style={{ marginBottom: 8, alignItems: "flex-end" }}>
      <Widget.FlexCol>
        <Font.Small
          style={{
            color: "rgba(0, 0, 0, 0.65)",
            height: 20,
            marginBottom: 8,
            lineHeight: "20px",
          }}
        >
          {unsavedLogic.type === "prevent" ? "選項" : "題目"}
        </Font.Small>
        <Widget.FlexRow style={{ alignItems: "stretch" }}>
          <Ant.Select
            allowClear
            showArrow
            className={`question-select ${
              unsavedLogic.type === "prevent" ? "input-items" : ""
            }`}
            size="large"
            style={
              unsavedLogic.type === "prevent"
                ? { marginLeft: 8, left: -8, minWidth: 470 }
                : unsavedLogic.type === "preventByQuestion"
                ? { marginLeft: 8, left: -8, width: 344 }
                : { marginLeft: 8, left: -8, width: 470 }
            }
            value={
              unsavedLogic.type === "prevent"
                ? props.action
                : props.action
                ? props.action
                : undefined
            }
            placeholder={unsavedLogic.type === "prevent" ? "選項" : "題目"}
            mode={unsavedLogic.type === "prevent" ? "multiple" : "single"}
            onChange={(value, option) => {
              props.updateAction(value);
              if (unsavedLogic.type === "preventByQuestion") {
                props.updateBlockOptionList([]);
                gneOptions(value);
              }
            }}
            optionLabelProp="label"
          >
            {unsavedLogic.type === "prevent" ? (
              <>
                {unsavedLogic.rules.length > 0 &&
                  _getPreventOptions(unsavedLogic.rules).map((o, idx) => {
                    return (
                      <Ant.Select.Option
                        key={idx}
                        value={o.idx}
                        label={`${o.idx + 1}`}
                      >
                        <OptionText className="option-text">
                          {displayOption(o, o.idx) || ""}
                        </OptionText>
                      </Ant.Select.Option>
                    );
                  })}
              </>
            ) : unsavedLogic.type === "preventByQuestion" ? (
              <>
                {preventByQuestion().map((q, idx) => {
                  return (
                    <Ant.Select.Option
                      key={q.id}
                      value={q.id}
                      label={displayJumpQuestion(q)}
                    >
                      <span
                        style={{ color: Color.Purple, marginRight: 5 }}
                      >{`${q.getIdxDisplay()}.`}</span>
                      <OptionText className="option-text">{`${catText(
                        q.content
                      ) || ""}`}</OptionText>
                    </Ant.Select.Option>
                  );
                })}
              </>
            ) : (
              <>
                {jumpQuestions.map((q, idx) => (
                  <Ant.Select.Option
                    key={q.id}
                    value={q.id}
                    label={displayJumpQuestion(q)}
                  >
                    <span
                      style={{ color: Color.Purple, marginRight: 5 }}
                    >{`${q.getIdxDisplay()}.`}</span>
                    <OptionText className="option-text">{`${catText(
                      q.content
                    ) || ""}`}</OptionText>
                  </Ant.Select.Option>
                ))}
              </>
            )}
          </Ant.Select>
          {unsavedLogic.type === "preventByQuestion" && (
            <Ant.Select
              allowClear
              showArrow
              placeholder="選項"
              style={{ marginLeft: 16, left: -8, width: 320 }}
              className="input-items"
              size="large"
              mode="multiple"
              onChange={props.updateBlockOptionList}
              value={
                unsavedLogic.blockOptionList && unsavedLogic.action !== null
                  ? unsavedLogic.blockOptionList
                  : []
              }
            >
              {options.map((o, idx) => (
                <Ant.Select.Option key={idx} value={o.id} label={`${idx + 1}`}>
                  {displayOption(o, idx) || ""}
                </Ant.Select.Option>
              ))}
            </Ant.Select>
          )}
        </Widget.FlexRow>
      </Widget.FlexCol>
    </Widget.FlexRow>
  );
}

const Wrapper = styled.div`
  width: 100%;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.12);

  .option-text {
    display: -webkit-box;
  }

  .content-wrapper {
    padding: 24px 36px 16px 48px;
  }

  & .select-xs-padding {
    .ant-select-selector {
      padding: 0 6px !important;
    }
  }

  & .pl-16 {
    .ant-select-selector {
      padding: 1px 16px !important;
    }
  }

  & .input-items {
    .ant-select-selector {
      height: 100%;

      .ant-select-selection-item {
        background-color: rgba(186, 182, 224, 0.3);
        border: 1px solid rgba(83, 74, 179, 0.3);
        width: 44px;
        height: 28px;
        padding-left: 4px;
        border-radius: 4px;
        font-size: ${FontSize.body}px;
        color: #837ae8;

        .ant-select-selection-item-content,
        .ant-select-selection-item-remove {
          line-height: 28px;
        }
      }
    }
  }

  .question-select {
    flex-grow: 1;

    .ant-select-selector {
      height: 100% !important;

      .ant-select-selection-placeholder,
      .ant-select-selection-search-input,
      .ant-select-selection-item {
        display: flex;
        align-items: center;
      }
    }
  }
`;

const RuleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  margin-bottom: 12px;
`;

const OptionText = styled.span`
  white-space: break-spaces;
  line-height: normal;
  display: inline;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const QuestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 280px;
`;

const ConditionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 112px;
  margin-left: 16px;
`;

const AnswerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 256px;
  margin-left: 16px;
`;

const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  margin-left: 12px;
  cursor: pointer;
  align-self: center;
`;

const MatrixRowOption = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: space-between;

  .input-items {
    width: 256px;
  }

  .ant-select-selector {
    height: 100%;
  }

  .matrix-select {
    flex: 0 1 49%;

    .ant-select-selector {
      height: 100% !important;

      .ant-select-selection-item,
      .ant-select-selection-placeholder {
        display: flex;
        align-items: center;
        font-size: 16px;
      }
    }
  }
`;

export default LogicRule;
