import React, { Component } from "react";
import styled from "styled-components";
import ActionCreator from "../ActionCreator";
import Selectors from "../Selectors";
import { connect } from "react-redux";
import * as Icon from "./Icon";
import QUESTION_TYPE from "../Domain/QuestionType";
import STEP from "../Domain/SurveyStep";
import MenuConfig from "./SurveyDetailSideMenu.config";
import MenuLogic from "./SurveyDetailSideMenu.logic";
import MenuCollect from "./SurveyDetailSideMenu.collect";

class SideMenu extends Component {
  render() {
    let { step } = this.props;
    // MENU，區分問卷，邏輯設計，收集方式，結果狀態切換元件
    switch (step) {
      case STEP.CONFIG.key:
        return <MenuConfig {...this.props} />;
      case STEP.LOGIC.key:
        return <MenuLogic {...this.props} />;
      case STEP.COLLECT.key:
        return <MenuCollect {...this.props} />;
      default:
        return null;
    }
  }
}

export default SideMenu;
