import React, { useState } from "react";
import styled from "styled-components";
import Widget, { Color, Font } from "./Widget";
import CallSplitIcon from "../svg-icons/call-split-24-px.svg";
import DoNotDisturbIcon from "../svg-icons/do-not-disturb-alt-24-px.svg";
import HighlightOffIcon from "../svg-icons/highlight-off-24-px.svg";
import * as Survey from "../Contexts/SurveyContext";
import * as Ant from "antd";

const Items = [
  { title: "跳題邏輯", icon: CallSplitIcon, type: "jump" },
  { title: "選項防呆", icon: DoNotDisturbIcon, type: "prevent" },
  { title: "強制結束問卷", icon: HighlightOffIcon, type: "forceQuit" },
  { title: "跨題選項防呆", icon: DoNotDisturbIcon, type: "preventByQuestion" },
];

const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

function SurveyDetailSideMenuLogic(props) {
  const [creating, setCreating] = useState(false);
  // 新增邏輯方法
  async function createLogicRule(type) {
    const hide = Ant.message.loading("創建中", 0);
    setCreating(true);
    try {
      const logicCnt = props.logicList.length;
      let logic = await Survey.Actions.createLogicRule(props.survey.id, type);
      logic.rules.push({
        question: null,
        condition: undefined,
        options: [],
      });
      props.setLogicList([...props.logicList, logic]);
      props.setEditingIdx(logic.id);
      // props.setEditingIdx(logicCnt);
      //props.setUpdater(props.updater + 1);

      await delay(500);
      const main = document.getElementById("mian");
      let mainHeight = main.scrollHeight;
      main.scrollTo({
        top: mainHeight + 120,
        behavior: "smooth",
      });
    } catch (ex) {
      // alert("系統異常，請洽系統管理員");
    }
    hide();
    setCreating(false);
  }

  return (
    <Wrapper
      disable={
        props.editingIdx !== null || props.survey.audit || !props.canEdit
      }
    >
      <Widget.FlexRow
        style={{
          padding: "12px 20px",
          borderLeft: `4px solid ${Color.Purple}`,
          backgroundColor: "rgba(0, 0, 0, 0.04)",
        }}
      >
        <Font.Head>邏輯規則</Font.Head>
      </Widget.FlexRow>
      {/* 呈現側邊選項，如果沒有在 create 狀態，點擊會執行 create 邏輯 */}
      {Items.map((item, idx) => (
        <Widget.FlexRow
          key={idx}
          style={{
            padding: "12px 24px",
            cursor: "pointer",
          }}
          onClick={() => !creating && createLogicRule(item.type)}
        >
          <item.icon className="logic-menu-icon" />
          <Font.Body style={{ color: "rgba(0,0,0,0.65)", cursor: "pointer" }}>
            {item.title}
          </Font.Body>
        </Widget.FlexRow>
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fdfcfc;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);

  ${props =>
    props.disable && "cursor: not-allowed; & > * { pointer-events: none; }"}

  .logic-menu-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
`;

export default SurveyDetailSideMenuLogic;
