import React, { useMemo, useState, useEffect } from "react";
import styled from "styled-components";
import * as Ant from "antd";
import { createEditor } from "slate";
import { withHistory } from "slate-history";
import { withReact, Slate, ReactEditor } from "slate-react";

import * as Icon from "./Icon";
import * as SvgIcon from "./SvgIcon";
import Widget, { FontSize, Color, Font } from "./Widget";
import clickOnce from "../Utils/ClickOnce";
import ElementInput from "./ElementInput";
import * as Survey from "../Contexts/SurveyContext";
import TextWelcomePageEdit from "./WelcomePage.text";
import RichTextEditor from "./RichTextEditor";
import PrivacyDisplay from "./PrivacyDisplay";
import EditOnHover from "./EditOnHover";

import { jsx } from "slate-hyperscript";

const RichTextUtils = require("../Utils/RichTextUtils");

const PageHeader = () => {
  return (
    <div className="header-wrapper">
      <div className="header">歡迎頁</div>
    </div>
  );
};

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

function beforeUpload(file) {
  const isJpgOrPng =
    file.type === "image/jpeg" ||
    file.type === "image/png" ||
    file.type === "image/jpg";

  if (!isJpgOrPng) {
    Ant.message.error("圖片上傳判斷僅限於 PNG、JPG、JPEG 格式");
  }

  const isLt2M = file.size / 1024 / 1024 < 2;

  if (!isLt2M) {
    Ant.message.error("檔案限制為 2MB！");
  }

  return isJpgOrPng && isLt2M;
}

export default function SurveyWelcome({
  survey,
  lastPage,
  pageIdx,
  begin,
  end,
  setUpdater,
  updater,
  ...props
}) {
  const _fileUploadProps = {
    action: ``,
    multiple: false,
    accept: "image/*",
    showUploadList: false,
    headers: {
      accessControlAllowOrigin: "",
      AccessControlAllowOrigin: "*",
      accessControlAllowMethods: "POST",
      accessControlAllowHeaders: "x-requested-with,content-type",
    },
  };

  const [loading, setLoading] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [isRedirect, setIsRedirect] = useState(true);
  const [buttonText, setButtonText] = useState("進入問卷");
  const [title, setTitle] = useState(
    '[{"type":"paragraph","children":[{"text":"範例?"}]}]'
  );
  const [linkText, setLinkText] = useState(
    "https://www.cht.com.tw/home/consumer"
  );
  const [isLinkTextBlank, setIsLinkTextBlank] = useState(false);
  const [isLinkTextFormatWrong, setIsLinkTextFormatWrong] = useState(false);
  const [webPicture, setWebPicture] = useState("../images/EndPage_Web.png");
  const [privacyInfo, setPrivacyInfo] = useState({});

  const deserialize = (el, markAttributes = {}) => {
    if (el.nodeType === Node.TEXT_NODE) {
      return jsx("text", markAttributes, el.textContent);
    } else if (el.nodeType !== Node.ELEMENT_NODE) {
      return null;
    }

    const nodeAttributes = { ...markAttributes };

    // define attributes for text nodes
    switch (el.nodeName) {
      case "strong":
        nodeAttributes.bold = true;
    }

    const children = Array.from(el.childNodes)
      .map(node => deserialize(node, nodeAttributes))
      .flat();

    if (children.length === 0) {
      children.push(jsx("text", nodeAttributes, ""));
    }

    console.log("el.nodeName: ", el.nodeName);

    switch (el.nodeName) {
      case "BODY":
        return jsx("fragment", {}, children);
      case "BR":
        return "\n";
      case "BLOCKQUOTE":
        return jsx("element", { type: "quote" }, children);
      case "P":
        return jsx("element", { type: "paragraph" }, children);
      case "A":
        return jsx(
          "element",
          { type: "link", url: el.getAttribute("href") },
          children
        );
      case "UL":
        return jsx("element", { type: "bulleted-list" }, children);
      case "LI":
        return jsx("element", { type: "list-item" }, children);
      case "H1":
        return jsx("element", { type: "header_one" }, children);
      case "H2":
        return jsx("element", { type: "header_two" }, children);
      default:
        return children;
    }
  };

  const initialValue = [
    {
      type: "align-left",
      children: [
        {
          type: "paragraph",
          children: [
            {
              text:
                "您好！感謝您參與此問卷調查，為瞭解客戶意見以提供更好服務，誠摯邀請您參加線上調查。您所填寫的資料我們僅會用於整體統計分析，不會揭露個人資訊，敬請安心填答。",
              size: "20",
              bold: true,
              color: "#324250",
            },
          ],
        },
      ],
    },
  ];

  const initialWelcomePage = {
    WelcomePagePic: "", //"https://hamipoint.cht.com.tw/images/cht-logo.png",
    WelcomePageStyle: initialValue, //welcomePageData.WelcomePageStyle
    ButtonSentence: "進入問卷",
  };

  const [welcomePageData, setWelcomePageData] = useState(initialWelcomePage);
  const [content, setContent] = useState();
  const [oldContent, setOldContent] = useState();
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);
  const editorDisplay = useMemo(
    () => withHistory(withReact(createEditor())),
    []
  );

  const handleUploadImg = async info => {
    let fileList = [...info.fileList];

    fileList = fileList.map(file => {
      return file;
    });

    let updatedFile = {
      ...fileList[fileList.length - 1],
    };

    if (updatedFile.originFileObj) {
      console.log("updatedFile", updatedFile);

      const isJpgOrPng =
        updatedFile.type === "image/jpeg" ||
        updatedFile.type === "image/png" ||
        updatedFile.type === "image/jpg";

      const isLt2M = updatedFile.size / 1024 / 1024 < 2;

      if (isJpgOrPng && isLt2M) {
        updatedFile.preview = await getBase64(updatedFile.originFileObj);
        setWebPicture(updatedFile.preview);
      }
    }
  };

  const saveOnClick = async () => {
    const reg = /^(http|https):\/\//;
    if (!linkText) {
      setIsLinkTextBlank(true);
      setIsLinkTextFormatWrong(false);
      return null;
    } else {
      setIsLinkTextBlank(false);
      if (reg.test(linkText)) {
        setIsLinkTextFormatWrong(false);
      } else {
        setIsLinkTextFormatWrong(true);
        return null;
      }
    }
    let data = {
      SurveyId: survey.id,
      WelcomePagePic: webPicture,
      WelcomePageStyle: JSON.stringify(content),
      ButtonSentence: buttonText,
    };
    try {
      let resp = await Survey.Actions.editWelcomePage(data);
      if (resp.code && resp.code == 206) {
        resp = await Survey.Actions.createWelcomePage(data);
      }
      setIsEdit(false);
      setWelcomePageData(data);
    } catch (ex) {
      // alert("系統異常，請洽系統管理員");
    }
  };

  const _contentDefault = () => {
    let data = {
      SurveyId: survey.id,
      WelcomePagePic: webPicture,
      WelcomePageStyle: initialValue,
      ButtonSentence: buttonText,
    };
    setWelcomePageData(data);
    setContent(initialValue);
  };

  const _setDefault = async () => {
    editorProps.resetContent();
    setIsEdit(false);
    setIsLinkTextBlank(false);
    setIsLinkTextFormatWrong(false);
  };

  const editorProps = useMemo(() => {
    const isContentBlank = () => {
      try {
        if (Array.isArray(editor.children)) {
          if (editor.children.length > 0) {
            let currNode = editor.children[0];
            while (currNode.children && currNode.children.length > 0) {
              currNode = currNode.children[0];
            }
            if (currNode.text && currNode.text.trim()) {
              return false;
            }
          }
        }
      } catch (err) {}
      return true;
    };
    const secureSetContent = content => {
      if (content && Array.isArray(content)) {
        if (content.length > 0) {
          setContent(content);
        } else {
          setContent(initialValue);
        }
      } else if (content && typeof content === "string") {
        setContent([
          {
            type: "paragraph",
            children: [{ text: content }],
          },
        ]);
      } else {
        setContent(initialValue);
      }
    };

    return {
      editor,
      setContent: secureSetContent,
      getContent: () => editor.children,
      isContentBlank: isContentBlank,
      resetSelection: () => {
        editor.selection = {
          anchor: { path: [0, 0, 0], offset: 0 },
          focus: { path: [0, 0, 0], offset: 0 },
        };
      },
      resetContent: text => {
        // we have to manually handle focus when force setting content,
        // see https://github.com/ianstormtaylor/slate/issues/3477
        editor.selection = {
          anchor: { path: [0, 0, 0], offset: 0 },
          focus: { path: [0, 0, 0], offset: 0 },
        };
        secureSetContent(text);
      },
    };
  }, [editor, content]);

  const isEmpty = content => {
    if (content.length === 1) {
      if (content[0].children.length === 1) {
        if (content[0].children[0].text === "") {
          return true;
        }
      }
    }
    return false;
  };

  const ContentDisplay = ({ content }) => {
    if (content !== undefined) {
      if (typeof content === "object") {
        return (
          <Slate editor={editorDisplay} value={content}>
            <RichTextEditor readOnly placeholder=" " />
          </Slate>
        );
      } else if (typeof content === "string") {
        return <div dangerouslySetInnerHTML={{ __html: content }}></div>;
      }
    }
    return (
      <Slate editor={editorDisplay} value={text}>
        <RichTextEditor readOnly placeholder=" " />
      </Slate>
    );
  };

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        let data = await Survey.Actions.fetchWelcomePage(survey.id);

        data = {
          SurveyId: survey.id,
          WelcomePagePic: data.WelcomePagePic,
          WelcomePageStyle: data.WelcomePageStyle
            ? JSON.parse(data.WelcomePageStyle)
            : initialValue,
          ButtonSentence: data.ButtonSentence,
          PrivacyContent: data.PrivacyContent,
          PrivacyVersion: data.PrivacyVersion,
        };
        data = data.SurveyId ? data : initialWelcomePage;
        setWelcomePageData(data);
        setContent(data.WelcomePageStyle);
        setOldContent(data.WelcomePageStyle);
        setButtonText(data.ButtonSentence ? data.ButtonSentence : "進入問卷");
        setWebPicture(data.WelcomePagePic);

        var content = null;
        try {
          content = JSON.parse(data.PrivacyContent);
        } catch (e) {}
        if (content == null) {
          const document = new DOMParser().parseFromString(
            data.PrivacyContent,
            "text/html"
          );
          content = deserialize(document.body);
        }

        setPrivacyInfo({
          content: content,
          version: data.PrivacyVersion,
        });

        setLoading(false);
      } catch (ex) {
        // alert("系統異常，請洽系統管理員");
        setLoading(false);
        throw ex;
      }
    }
    fetchData();
  }, [isEdit]);

  useEffect(() => {
    editorProps.setContent(content);
  }, [editorProps, content]);

  if (loading) {
    return (
      <div
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "fixed",
          zIndex: "999999",
        }}
      >
        <Ant.Spin />
      </div>
    );
  }

  return (
    <Slate
      editor={editor}
      value={content}
      onChange={value => {
        if (isEmpty(value) && !isEmpty(content)) {
          ReactEditor.blur(editor);
        }
        setContent(value);
      }}
    >
      <Wrapper className="questions-content">
        <PageHeader />
        <FinishContainer>
          {!isEdit && (
            <NotEditWrapper>
              <NoEditTitleWrapper>
                <div className="title">{survey.title}</div>
              </NoEditTitleWrapper>
              <div
                className="not-edit-content-wrapper"
                onDoubleClick={() => setIsEdit(true)}
              >
                <DefaultImg>
                  {/* <div className="inner" style={{ backgroundImage: "url()" }}></div> */}
                  {welcomePageData.WelcomePagePic && (
                    <img
                      src={welcomePageData.WelcomePagePic}
                      style={{ width: "100%" }}
                    />
                  )}
                </DefaultImg>
                <NoEditDescWrapper>
                  <DefaultText>
                    {/* <h3></h3> */}
                    {content && (
                      <div className="text-wrapper">
                        <div className="content">
                          {!loading && (
                            // <RichTextEditor readOnly={true} />
                            <TextWelcomePageEdit
                              {...props}
                              editorProps={editorProps}
                              readOnly
                            />
                          )}
                          {/* <TextEndPageEdit {...props} readOnly={true} /> */}
                        </div>
                      </div>
                    )}
                  </DefaultText>
                </NoEditDescWrapper>
                <Ant.Divider style={{ margin: "0px 0px 16px" }} />
                <NoEditPrivacyWrapper>
                  <Font.SubTitle className="sub-title-wrapper">
                    中華電信股份有限公司問卷調查服務之個人資料蒐集告知聲明暨同意
                  </Font.SubTitle>
                  <Ant.Row style={{ marginBottom: "16px" }}>
                    <PrivacyDisplay
                      privacyInfo={privacyInfo}
                      isEdit={isEdit}
                      isPreview={false}
                    />
                  </Ant.Row>
                  <Ant.Row style={{ "margin-bottom": "8px" }}>
                    <Ant.Typography.Text>
                      您是否同意中華電信股份有限公司問卷調查服務之個人資料蒐集告知聲明
                    </Ant.Typography.Text>
                  </Ant.Row>
                  <Ant.Row style={{ "margin-bottom": "16px" }}>
                    <Ant.Radio.Group value={0}>
                      <Ant.Radio value={1}>同意</Ant.Radio>
                      <Ant.Radio value={2}>不同意</Ant.Radio>
                    </Ant.Radio.Group>
                  </Ant.Row>
                  <Ant.Row style={{ "margin-bottom": "8px" }}>
                    <Ant.Typography.Text>
                      我們向您寄送第三人(關係企業或企業客戶)的商品/服務資訊。未勾選者視為不同意，若不同意並不影響使用我們的服務。
                      <span style={{ color: "#0b90fc" }}>
                        建議您勾選「同意」，才不會錯過好康的機會、折扣資訊、優惠方案及更多的服務。
                      </span>
                    </Ant.Typography.Text>
                  </Ant.Row>
                  <Ant.Row>
                    <Ant.Radio.Group value={0}>
                      <Ant.Radio value={1}>同意</Ant.Radio>
                      <Ant.Radio value={2}>不同意</Ant.Radio>
                    </Ant.Radio.Group>
                  </Ant.Row>
                </NoEditPrivacyWrapper>
                <DefaultButtonWrapper>
                  <div className="default-button">
                    {welcomePageData.ButtonSentence ?? "進入問卷"}
                  </div>
                </DefaultButtonWrapper>
                {!survey.audit && (
                  <div className="actions">
                    <div className="wrapper" onClick={() => setIsEdit(true)}>
                      <div className="action-item">
                        <SvgIcon.EditMode
                          color={Color.GreyBlack}
                          size={FontSize.largeTitle}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </NotEditWrapper>
          )}
          {isEdit && (
            <EditWrapper>
              <EditTitleWrapper>
                <div className="title">{survey.title}</div>
              </EditTitleWrapper>
              <div className="edit-content-wrapper">
                <ImgWrapper>
                  <div className="title">上方圖片</div>
                  <div className="imgWrapper-content">
                    {webPicture && (
                      <EditOnHover
                        style={{ width: "100%" }}
                        onDelete={() => setWebPicture("")}
                      >
                        <img src={webPicture} style={{ width: "100%" }} />
                      </EditOnHover>
                    )}
                  </div>
                  <WarnLabel style={{ marginBottom: 16 }}>
                    僅限插入一個圖片
                  </WarnLabel>
                  <Ant.Upload
                    name="ending-page"
                    className="imgWrapper-content-uploader"
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    onChange={handleUploadImg}
                  >
                    <Ant.Button className="btn outlineBtn" type="primary">
                      <p>插入圖片</p>
                    </Ant.Button>
                  </Ant.Upload>
                </ImgWrapper>
                <Ant.Divider style={{ margin: "0px 0px 24px" }} />
                <div className="text-wrapper">
                  <div className="title">文字</div>
                  <div className="content">
                    {!loading && (
                      <TextWelcomePageEdit
                        {...props}
                        editorProps={editorProps}
                        welcomePage={true}
                        style={{ padding: 0 }}
                      />
                    )}
                    {/* <TextEndPageEdit
                      {...props}
                      editorProps={editorProps}
                      endingPage={true}
                      style={{ padding: 0 }}
                    /> */}
                  </div>
                </div>
                <div className="warn-label-wrapper">
                  <WarnLabel>
                    歡迎頁預設格式為思源黑體粗體，字級 20 pt
                  </WarnLabel>
                  <div
                    style={{
                      width: 70,
                      textAlign: "center",
                      fontSize: `${FontSize.body}px`,
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "1.57em",
                      letterSpacing: "normal",
                      color: "#534ab3",
                      cursor: "pointer",
                    }}
                    onClick={_contentDefault}
                  >
                    返回預設值
                  </div>
                </div>
                <Ant.Divider style={{ margin: "0px 0px 24px" }} />
                <PrivacyContentWrapper>
                  <div className="title" style={{ marginBottom: "8px" }}>
                    個資聲明文字
                  </div>
                  <div className="tip-block">
                    <span>問卷填寫者必須同意「個資聲明」才能正式進入問卷</span>
                  </div>
                  <div class="content">
                    <ContentDisplay content={privacyInfo.content} />
                  </div>
                </PrivacyContentWrapper>
                <Ant.Divider style={{ margin: "0px 0px 24px" }} />
                <BtnWrapper>
                  <div className="title" style={{ marginBottom: "16px" }}>
                    下一步按鈕
                  </div>
                  <div className="sub" style={{ marginBottom: "8px" }}>
                    按鈕文字
                  </div>
                  <ElementInput
                    width="100%"
                    size="md"
                    className=""
                    placeholder={buttonText}
                    value={buttonText}
                    type="text"
                    onChange={e => setButtonText(e.target.value)}
                  />
                </BtnWrapper>
              </div>
              <FooterBtnWrapper>
                <Ant.Button
                  className="btn outlineBtn"
                  type="primary"
                  style={{ marginRight: 16 }}
                  onClick={_setDefault}
                >
                  <p>取消</p>
                </Ant.Button>
                <Ant.Button
                  className="btn defaultBtn"
                  type="primary"
                  style={{ marginRight: 0 }}
                  onClick={() => saveOnClick()}
                >
                  <p>儲存</p>
                </Ant.Button>
              </FooterBtnWrapper>
            </EditWrapper>
          )}
        </FinishContainer>
      </Wrapper>
    </Slate>
  );
}

export function WarnLabel({ style = {}, children, ...props }) {
  return (
    <FlexRow style={style}>
      <Icon.InfoCircleOutlined color={Color.LightGold} />
      <Font.Body style={{ marginLeft: 6, color: Color.LightGold }}>
        {children}
      </Font.Body>
    </FlexRow>
  );
}

const FlexRow = ({ style = {}, ...props }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      ...style,
    }}
    {...props}
  >
    {props.children}
  </div>
);

const PreviewBox = styled.div``;

const ColorBox = styled.div`
  position: relative;
  overflow: hidden;
  border: 1px solid grey;
  background-color: ${props => props.color};
  height: 20px;
  width: 100px;

  & :before {
    ${props => (props.color === "transparent" ? "content: '';" : "")}
    width: 1px;
    display: block;
    height: 120px;
    background-color: #a6a6a6;
    position: absolute;
    transform: rotate(-79.5deg);
    transform-origin: top;
  }
`;

const ColorCircle = styled.div`
  position: relative;
  overflow: hidden;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border-radius: ${props => props.size / 2}px;
  border: 1px solid #a6a6a6;
  background-color: ${props => props.color};
  margin: 10px;

  & :before {
    ${props => (props.color === "transparent" ? "content: '';" : "")}
    width: 1px;
    display: block;
    height: 40px;
    background-color: #a6a6a6;
    position: absolute;
    transform: rotate(-45deg) translateX(13px);
  }
`;

const Wrapper = styled.div`
  position: relative;
  border-radius: 5px;
  margin: 12px auto;
  background-color: #fff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);

  ${props => props.disable && "pointer-events: none;"}

  & .title {
    font-size: ${FontSize.largeTitle}px;
    color: #474747;
    padding: 24px 32px;
    margin-bottom: 16px;
  }

  & .header-wrapper {
    position: relative;
    width: 100%;
    height: 32;
    & .header {
      background-color: #dcdaef;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      color: #756ec2;
      font-size: ${FontSize.body}px;
      padding: 5px 24px;
    }
  }

  @keyframes show {
    from {
      transform: translateX(-10%);
      z-index: -1;
      opacity: 0;
    }
    to {
      transform: translateX(100%);
      z-index: 1;
      opacity: 1;
    }
  }
  .actions {
    display: block;
    pointer-events: none;
    opacity: 0;
    position: absolute;
    padding-left: 16px;
    right: 0px;
    top: 0px;
    /* transform: translateX(-15%); */
    transform: translateX(0%);
    transition: 200ms;
    z-index: -1;
    pointer-events: all;

    .wrapper {
      display: flex;
      flex-direction: column;
      background-color: #fff;
      padding: 20px 12px;
      border-radius: 4px;
      box-shadow: 0 9px 28px 8px rgba(0, 0, 0, 0.05),
        0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12);
      cursor: pointer;

      .action-item {
        width: 24px;
        height: 24px;
      }
    }
  }
  .btn {
    width: 88px;
    height: 32px;
    margin: 0;
    border-radius: 0;
    text-align: center;
    margin-right: 18px;
    &.defaultBtn {
      margin-right: 32px;
    }
    p {
      font-size: ${FontSize.body}px;
      line-height: 1.57;
      margin: 0 auto;
    }
  }
  .outlineBtn {
    border: solid 1px ${Color.MPurple_6_85};
    background-color: rgba(255, 255, 255, 0.4);
    p {
      color: ${Color.MPurple_6};
    }
    &:hover {
      border-color: ${Color.LightPurple_45};
      background-color: ${Color.MPurple_1_50};
      p {
        color: ${Color.LightPurple_45};
      }
    }
    &:active {
      border-color: ${Color.MPurple_6};
      background-color: rgba(186, 182, 224, 0.3);
      p {
        color: ${Color.MPurple_6};
      }
    }
    &:focus {
      /* -webkit-filter: blur(1px); */
      /* filter: blur(1px); */
      -webkit-box-shadow: 0 0 4px 2px ${Color.MPurple_1_50};
      -moz-box-shadow: 0 0 4px 2px ${Color.MPurple_1_50};
      box-shadow: 0 0 4px 2px ${Color.MPurple_1_50};
      border-color: ${Color.LightPurple_45};
      p {
        color: ${Color.MPurple_5};
      }
    }
    &[disabled] {
      border-color: ${Color.Black_15};
      background-color: ${Color.Black_10};
      p {
        color: ${Color.GreyBlack_25};
      }
    }
  }
  .defaultBtn {
    border: solid 1px ${Color.MPurple_6};
    background-color: ${Color.MPurple_6};
    p {
      color: #ffffff;
    }
    &:hover {
      border-color: ${Color.LightPurple_45};
      background-color: ${Color.LightPurple_45};
    }
    &:active {
      border-color: ${Color.MPurple_7};
      background-color: ${Color.MPurple_7};
    }
    &:focus {
      /* -webkit-filter: blur(1px); */
      /* filter: blur(1px); */
      -webkit-box-shadow: 0 0 4px 2px ${Color.LightPurple_45};
      -moz-box-shadow: 0 0 4px 2px ${Color.LightPurple_45};
      box-shadow: 0 0 4px 2px ${Color.LightPurple_45};
      border-color: ${Color.LightPurple_45};
      p {
        color: ${Color.MPurple_5};
      }
    }
    &[disabled] {
      border-color: ${Color.Black_15};
      background-color: ${Color.Black_15};
      p {
        color: ${Color.GreyBlack_25};
      }
    }
  }
`;

const FinishContainer = styled.div`
  position: relative;
  background-color: #ffffff;
  transition: background 300ms linear;
`;

const NotEditWrapper = styled.div`
  &:hover {
    .actions {
      z-index: 1;
      opacity: 1;
      transform: translateX(100%);
    }
  }

  .not-edit-content-wrapper {
    padding: 16px 36px 70px 36px;

    &:hover {
      background-color: rgba(237, 236, 247, 0.45);
    }
  }
`;

const DefaultImg = styled.div`
  display: block;
  width: 100%;
  height: auto;
  background-color: #fafafa;
  margin-bottom: 24px;

  .inner {
    display: block;
    width: 800px;
    height: 227px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
`;

const DefaultText = styled.div`
  display: block;
  width: 100%;
  height: auto;
  margin-bottom: 60px;
  h3 {
    font-size: ${FontSize.largeTitle}px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.32px;
    text-align: center;
    color: #0075d3;
  }
`;

const DefaultButtonWrapper = styled.div`
  width: 100%;
  height: auto;

  @media screen and (max-width: 823px) {
    padding-top: 96px;
    padding-bottom: 204px;
  }

  .default-button {
    width: 240px;
    height: 48px;
    padding: 12px 67px;
    background-color: rgba(0, 0, 0, 0.15);
    font-size: ${FontSize.head}px;
    color: rgba(0, 0, 0, 0.25);
    text-align: center;
    margin: 0 auto;

    @media screen and (max-width: 823px) {
      min-width: 98px;
      height: 40px;
    }

    &:disable {
      background-color: ${Color.Black_15};
      border: solid 1px ${Color.Black_15};
      color: ${Color.Black_10};
    }
  }
`;

const EditWrapper = styled.div`
  .edit-content-wrapper {
    padding: 16px 36px 24px;
  }

  .title {
    font-size: ${FontSize.head}px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 24px;
    letter-spacing: normal;
    color: ${Color.Black_85};
    padding: 0;
  }

  .sub {
    font-size: ${FontSize.body}px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 22px;
    letter-spacing: normal;
    color: ${Color.Black_65};
  }

  .text-wrapper {
    margin-bottom: 9px;
  }

  .warn-label-wrapper {
    margin-bottom: 29px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const ImgWrapper = styled.div`
  margin-bottom: 24px;

  .title {
    margin-bottom: 8px;
  }

  .imgWrapper-content {
    margin-left: 32px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;

    &-uploader {
      max-width: 133px;
      max-height: 100px;
      margin-right: 16px;

      .ant-upload {
        width: 100%;
      }
    }
  }

  .imgWrapper-content-uploader {
    .btn {
      width: 88px;
      height: 32px;
      margin-right: 0;
    }
  }
`;

const BtnWrapper = styled.div``;

const FooterBtnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 15px 43px 15px 43px;

  .btn {
    width: 60px;
    height: 32px;
  }
`;

const EditTitleWrapper = styled.div`
  padding: 21px 36px;

  .title {
    font-size: 24px;
    font-weight: normal;
    color: #474747;
    padding: 0;
    margin: 0;
  }
`;

const PrivacyContentWrapper = styled.div`
  margin-bottom: 24px;

  .tip-block {
    width: 100%;
    height: 38px;
    margin-bottom: 16px;
    padding: 8px 10px 8px 12px;
    border-radius: 8px;
    background-color: rgba(109, 190, 255, 0.2);

    span {
      width: 715px;
      height: 22px;
      font-family: PingFangTC;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      color: #0075d3;
    }
  }

  .content {
    width: 100%;
    height: 390px;
    padding: 12px 16px 16px;
    border: 1px solid ${Color.GreyBlack_15};
    overflow-y: scroll;
  }
`;

const NoEditTitleWrapper = styled.div`
  padding: 21px 32px;

  .title {
    font-size: 24px;
    font-weight: normal;
    color: #474747;
    padding: 0;
    margin: 0;
  }
`;

const NoEditDescWrapper = styled.div`
  margin-bottom: 60px;
`;

const NoEditPrivacyWrapper = styled.div`
  margin-bottom: 60px;

  .sub-title-wrapper {
    text-align: center;
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: 500;
    color: #324250;
  }
`;
