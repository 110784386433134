import React from "react";
import * as Icon from "./Icon";
import Widget, { Color, Font, FontSize } from "./Widget";
import * as Ant from "antd";
import * as Survey from "../Contexts/SurveyContext";
import clickOnce from "../Utils/ClickOnce";

const radioStyle = {
  display: "flex",
  alignItems: "center",
  height: "30px",
  lineHeight: "30px",
  marginBottom: 5,
};

export default function ProgressConfig({ goBack, survey, ...props }) {
  return (
    <div>
      <Widget.FlexRow style={{ padding: 6 }}>
        <Icon.KeyboardArrowLeft
          size={FontSize.largeTitle}
          color={Color.GreyBlack_45}
          onClick={goBack}
        />
        <div style={{ flex: 1 }}>顯示填寫進度</div>
        <Widget.DetailedSwitch
          size={"small"}
          wrapperStyle={{ marginRight: 5 }}
          checked={survey.progressDisplay}
          onChange={clickOnce(async checked => {
            const hide = Ant.message.loading("更新中", 0);
            try {
              props.setSurvey(
                await Survey.Actions.updatePropertyById(
                  survey.id,
                  { progressDisplay: checked },
                  props.unModelSurvey
                )
              );
            } catch (ex) {}
            hide();
            // await Survey.Actions.updatePropertyById(survey.id, {
            //   progressDisplay: checked,
            // });
            // props.setUpdater(props.updater + 1);
          })}
        />
      </Widget.FlexRow>

      <Widget.Line css="background-color: rgba(0, 0, 0, 0.1);" />

      <div style={{ padding: "15.5px 30px" }}>
        <Font.Body style={{ marginBottom: 8 }}>位置</Font.Body>
        <Ant.Radio.Group
          value={survey.progressDisplayDetail.position}
          onChange={clickOnce(async e => {
            const hide = Ant.message.loading("更新中", 0);
            try {
              props.setSurvey(
                await Survey.Actions.updatePropertyById(
                  survey.id,
                  {
                    progressDisplayDetail: {
                      ...survey.progressDisplayDetail,
                      position: e.target.value,
                    },
                  },
                  props.unModelSurvey
                )
              );
            } catch (ex) {}
            hide();
            // await Survey.Actions.updatePropertyById(survey.id, {
            //   progressDisplayDetail: {
            //     ...survey.progressDisplayDetail,
            //     position: e.target.value,
            //   },
            // });
            // props.setUpdater(props.updater + 1);
          })}
        >
          {/* <Ant.Radio style={radioStyle} value="top" disabled={true}>
            頁面上方
          </Ant.Radio> */}
          <Ant.Radio style={radioStyle} value="bottom">
            頁面底部
          </Ant.Radio>
        </Ant.Radio.Group>
      </div>

      <Widget.Line css="background-color: rgba(0, 0, 0, 0.1);" />

      <div style={{ padding: "15.5px 30px" }}>
        <Font.Body style={{ marginBottom: 8 }}>顯示方式</Font.Body>
        <Ant.Radio.Group
          value={survey.progressDisplayDetail.display}
          onChange={clickOnce(async e => {
            const hide = Ant.message.loading("更新中", 0);
            try {
              props.setSurvey(
                await Survey.Actions.updatePropertyById(
                  survey.id,
                  {
                    progressDisplayDetail: {
                      ...survey.progressDisplayDetail,
                      display: e.target.value,
                    },
                  },
                  props.unModelSurvey
                )
              );
            } catch (ex) {}
            hide();
            // await Survey.Actions.updatePropertyById(survey.id, {
            //   progressDisplayDetail: {
            //     ...survey.progressDisplayDetail,
            //     display: e.target.value,
            //   },
            // });
            // props.setUpdater(props.updater + 1);
          })}
        >
          <Ant.Radio style={radioStyle} value="percentage">
            完成百分比例
          </Ant.Radio>
          <Ant.Radio style={radioStyle} value="questions">
            完成題目比例
          </Ant.Radio>
        </Ant.Radio.Group>
      </div>
      <Widget.Line css="background-color: rgba(0, 0, 0, 0.1);" />
    </div>
  );
}
