const STEP = {
  CONFIG: {
    display: "設計問卷",
    key: 0,
  },
  LOGIC: {
    display: "邏輯規則",
    key: 1,
  },
  COLLECT: {
    display: "收集方式",
    key: 2,
  },
  RESULT: {
    display: "成效結果",
    key: 3,
  },
};

export default STEP;
