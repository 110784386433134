import React, { useState, useEffect } from "react";
import styled from "styled-components";
import * as Ant from "antd";

import BarChart from "./BarChart";
import MatrixStatisticsTable from "./MatrixStatisticsTable";
import Widget, { Color, FontSize, Font } from "./Widget";
import * as Icon from "./Icon";
import QuestionType from "../Domain/QuestionType";
import * as Survey from "../Contexts/SurveyContext";
import { catText } from "../Utils/RichTextUtils";

const queryString = require("query-string");
const NonStatisticQuestionType = ["FILL_IN", "BASIC_INFO"];
// 問卷填寫狀態，成效分析
const QuestionStatCaption = props => {
  const { question, statisticsQuestionData, extraInfo } = props;

  return (
    <div>
      <Font.Head style={{ color: Color.GreyBlack_45, marginBottom: 4 }}>{`${
        QuestionType[question.type].display
      }`}</Font.Head>
      <Widget.FlexRow>
        <Widget.FlexCenter
          style={{
            backgroundColor: "rgba(15, 14, 35, 0.8)",
            width: 32,
            height: 32,
            color: "white",
          }}
        >
          {question.getIdxDisplay()}
        </Widget.FlexCenter>
        <div
          style={{
            color: Color.Red,
            fontSize: `${FontSize.body}px`,
            marginLeft: 4,
          }}
        >
          {question.required ? "*" : ""}
        </div>

        <Font.Head
          style={{ flex: 1, marginLeft: 7, color: Color.GreyBlack_85 }}
        >{`${statisticsQuestionData.questionTitle}`}</Font.Head>
        <div
          style={{ color: Color.Purple }}
        >{`填寫共 ${statisticsQuestionData.respCount} 次`}</div>
      </Widget.FlexRow>
      {!extraInfo ? (
        <Widget.FlexRow
          style={{
            marginTop: 9,
            marginLeft: 32,
            visibility: "hidden",
            minHeight: 22,
          }}
        >
          <Icon.InfoCircleOutlined
            color={Color.LightGold}
            style={{ marginRight: 8 }}
          />
          <Font.Body style={{ color: Color.LightGold }}>{extraInfo}</Font.Body>
        </Widget.FlexRow>
      ) : (
        <Widget.FlexRow style={{ marginTop: 9, marginLeft: 32 }}>
          <Icon.InfoCircleOutlined
            color={Color.LightGold}
            style={{ marginRight: 8 }}
          />
          <Font.Body style={{ color: Color.LightGold }}>{extraInfo}</Font.Body>
        </Widget.FlexRow>
      )}
    </div>
  );
};

const SurveyStat = props => {
  const {
    statisticsData,
    survey,
    backToCollectStep,
    loading,
    setIsSticky,
  } = props;

  const [load, setLoad] = useState({ statisticData: false });
  const [limitStatisticData, setLimitStatisticData] = useState({});
  const [dataType, setDataType] = useState("live");
  const [collection, setCollection] = useState("1");

  const questions = limitStatisticData?.QuestionDataList;
  const question1 = questions && questions[0];
  const question2 = questions && questions[1];
  // 成效內容資訊
  const _getExtraInfo = question => {
    if (
      dataType === "live" &&
      NonStatisticQuestionType.indexOf(question.type) !== -1
    ) {
      return `${
        QuestionType[question.type].display
      }的答題內容，請至CRM下載問卷結果閱讀`;
    } else if (
      dataType === "live" &&
      question.options.filter(q => q.type === "others").length > 0
    ) {
      return "其他選項的答題內容，請至CRM下載問卷結果閱讀";
    }
    return "";
  };

  const renderExtraElement = () => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ marginRight: 16, fontSize: 16 }}>資料切換</div>
        <Ant.Select
          style={{ marginRight: 8 }}
          bordered={false}
          defaultValue={dataType}
          value={dataType}
          onChange={value => {
            setDataType(value);
            fetchLimitStatisticData(collection, value);
          }}
        >
          <Ant.Select.Option value="live">正式資料</Ant.Select.Option>
          <Ant.Select.Option value="test">測試資料</Ant.Select.Option>
        </Ant.Select>
        <Ant.Select
          bordered={false}
          dropdownMatchSelectWidth={150}
          defaultValue={collection}
          value={collection}
          onChange={value => {
            setCollection(value);
            fetchLimitStatisticData(value, dataType);
          }}
        >
          <Ant.Select.Option value="1">網頁收集方式</Ant.Select.Option>
          <Ant.Select.Option value="2">QR code 收集方式</Ant.Select.Option>
        </Ant.Select>
      </div>
    );
  };

  const columns = [
    {
      title: "第一題題目的選項",
      dataIndex: "OptionDataList",
      width: 250,
      render: (text, record, index) => {
        const opt = record.OptionDataList
          ? record.OptionDataList[0]
          : record.OptionDataList;
        if (opt === null || opt === undefined) {
          return <div>-</div>;
        }
        return `${opt.OptionSeq} ${opt.OptionContent}`;
      },
    },
    {
      title: "第二題題目的選項",
      dataIndex: "OptionDataList",
      width: 250,
      render: (text, record, index) => {
        const opt = record.OptionDataList
          ? record.OptionDataList[1]
          : record.OptionDataList;
        if (opt === null || opt === undefined) {
          return <div>-</div>;
        }
        return `${opt.OptionSeq} ${opt.OptionContent}`;
      },
    },
    {
      title: "今日新增數量",
      dataIndex: "Today",
      key: "Today",
      width: 120,
      align: "right",
      render: (text, record, index) => {
        const textStyle = { color: "#0da789", fontWeight: 500 };
        if (text === null || text === undefined) {
          return <div>-</div>;
        }
        return <div style={textStyle}>+{text}</div>;
      },
    },
    {
      title: "回覆數量",
      dataIndex: "Total",
      key: "Total",
      width: 120,
      align: "right",
      render: (text, record, index) => {
        const textStyle = { color: Color.GreyBlack_65, fontWeight: 500 };
        if (text === null || text === undefined) {
          return <div>-</div>;
        }
        return <div style={textStyle}>{text}</div>;
      },
    },
    {
      title: "數量上限值",
      dataIndex: "LimitQty",
      key: "LimitQty",
      width: 110,
      align: "right",
      render: (text, record, index) => {
        const textStyle = { color: Color.GreyBlack_65, fontWeight: 500 };
        if (text === null || text === undefined) {
          return <div>-</div>;
        }
        return <div style={textStyle}>{text}</div>;
      },
    },
    {
      title: "目標差額",
      width: 100,
      align: "right",
      render: (text, record, index) => {
        const textStyle = { color: Color.Purple, fontWeight: 500 };
        const diff = record.LimitQty - record.Total;
        return <div style={textStyle}>{diff === 0 ? "-" : diff}</div>;
      },
    },
    {
      title: "達成率(%)",
      width: 100,
      align: "right",
      render: (text, record, index) => {
        const textStyle = { color: Color.Purple, fontWeight: 500 };
        return (
          <div style={textStyle}>
            {`${((record.Total / record.LimitQty) * 100).toFixed(1)}%`}
          </div>
        );
      },
    },
  ];
  // 抓取選項限制成效內容
  async function fetchLimitStatisticData(collection, dataType) {
    setLoad(state => ({ ...state, statisticData: true }));
    const id = queryString.parse(props?.location?.search)?.id;
    const res = await Survey.Actions.fetchLimitStatisticData(
      id,
      collection,
      dataType === "test"
    );
    setLimitStatisticData(res.data);
    setLoad(state => ({ ...state, statisticData: false }));
  }

  const setQuestionText = question => {
    const s = question?.QuestionSeq ?? "";
    const t = question?.QuestionSubject
      ? catText(JSON.parse(question?.QuestionSubject))
      : "";
    return `${s} ${t}`;
  };
  // 預設抓取選項限制成效內容
  useEffect(() => {
    fetchLimitStatisticData(collection, dataType);
  }, []);

  useEffect(() => {
    setIsSticky(true);
    return () => {
      setIsSticky(false);
    };
  }, []);

  if (!statisticsData && loading) {
    return (
      <OutsideWrapper>
        <div
          style={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "fixed",
            zIndex: "999999",
          }}
        >
          <Ant.Spin />
        </div>
      </OutsideWrapper>
    );
  }

  if (!loading && (statisticsData?.length === 0 || !statisticsData)) {
    return (
      <OutsideWrapper>
        <Widget.FlexCenter style={{ marginTop: 128 }}>
          <Ant.Empty />
          <Font.Title
            style={{
              margin: "24px 10px",
              textAlign: "center",
              fontSize: `${FontSize.subTitle}px`,
              color: Color.BlackBlack_1,
            }}
          >
            目前尚無題目
            <br />
            請先新增一個題目
          </Font.Title>

          <Ant.Button
            style={{ height: 40, padding: "8px 14px 8px 10px" }}
            icon={
              <Icon.KeyboardArrowLeft
                style={{ marginRight: 8 }}
                size={FontSize.title}
                color={"#fff"}
              />
            }
            type="primary"
            onClick={backToCollectStep}
          >
            前往設計問卷
          </Ant.Button>
        </Widget.FlexCenter>
      </OutsideWrapper>
    );
  }

  return (
    <>
      <Loading loading={load.statisticData}>
        <Ant.Spin />
      </Loading>
      <StatWrapper>
        <Ant.Tabs
          defaultActiveKey="1"
          tabBarStyle={{ width: "100%", backgroundColor: "#fff" }}
          className="stat-tabs"
          tabBarExtraContent={renderExtraElement()}
          animated={false}
        >
          <Ant.Tabs.TabPane tab="選項組合回覆" key="1">
            <OptionSetReplyWrapper>
              <div style={{ display: "flex", marginBottom: 36 }}>
                <TotalAmountCard className="card">
                  <div className="title">總填寫次數</div>
                  <div>
                    <span className="amount-total">
                      {limitStatisticData.SummaryTotal ?? 0}
                    </span>
                    <span className="amount-per-day">
                      <Ant.Tooltip title="每天新增問卷回覆數量">{`+${limitStatisticData.SummaryToday ??
                        0}`}</Ant.Tooltip>
                    </span>
                  </div>
                </TotalAmountCard>
                <DataDownloadCard className="card">
                  <div className="title">資料下載</div>
                  <div>
                    <span className="index">1.</span>
                    CRM填寫「名單匯出申請表」，申請事由須註明問卷是否含有個資。
                  </div>
                  <div style={{ display: "flex" }}>
                    <span className="index">2.</span>
                    <span>
                      陳核通過後，請將申請表截圖發信至 CRM
                      客服信箱，告知哪份問卷需匯出，含有個資問卷將採 DRM
                      加密提供。
                    </span>
                  </div>
                </DataDownloadCard>
              </div>
              <ReplyCard className="card">
                <div className="title">選項組合回覆</div>
                {limitStatisticData.Id ? (
                  <>
                    <div className="question-wrapper">
                      <div className="question">
                        <div className="question-title">第一題題目</div>
                        <div className="question-input">
                          <Ant.Input value={setQuestionText(question1)} />
                        </div>
                      </div>
                      {question2 && (
                        <div className="question">
                          <div className="question-title">第二題題目</div>
                          <div className="question-input">
                            <Ant.Input value={setQuestionText(question2)} />
                          </div>
                        </div>
                      )}
                    </div>
                    <Ant.Divider style={{ marginBottom: 40 }} />
                    <div className="reply-wrapper">
                      <div className="title">選項回覆數量</div>
                      <div className="table">
                        <Ant.Table
                          columns={columns}
                          dataSource={limitStatisticData.Options}
                          pagination={false}
                          bordered
                          rowClassName="table-row"
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <Ant.Empty />
                )}
              </ReplyCard>
            </OptionSetReplyWrapper>
          </Ant.Tabs.TabPane>
          <Ant.Tabs.TabPane tab="統計圖表" key="2">
            <ResultWrapper>
              {survey.questions
                ?.filter(q => q.isRealQuestion())
                .map((q, idx) => {
                  const data = statisticsData[dataType].questions.find(
                    datum => datum.questionId === q.id
                  );
                  if (!data) {
                    return null;
                  } else if (NonStatisticQuestionType.indexOf(q.type) !== -1) {
                    return (
                      <QuestionStatDisplayWrapper key={idx}>
                        <QuestionStatCaption
                          question={q}
                          statisticsQuestionData={data}
                          extraInfo={_getExtraInfo(q)}
                        />
                      </QuestionStatDisplayWrapper>
                    );
                  } else if (q.type === "MATRIX") {
                    let cols = data.cols;
                    let rows = data.rows;
                    let colsObj = data.optionMatrixStats;
                    return (
                      <QuestionStatDisplayWrapper key={idx}>
                        <QuestionStatCaption
                          question={q}
                          statisticsQuestionData={data}
                          extraInfo={_getExtraInfo(q)}
                        />
                        <MatrixStatisticsTable
                          rows={rows}
                          cols={cols}
                          colsObj={colsObj}
                        />
                      </QuestionStatDisplayWrapper>
                    );
                  } else {
                    let rows = [];
                    let x_Axis_Array = data.x_Axis.split(";");
                    x_Axis_Array.shift();
                    let gridLines = x_Axis_Array;
                    if (!x_Axis_Array.length) {
                      gridLines = [2, 4, 6, 8, 10];
                    }
                    if (
                      Array.isArray(data.optionStats) &&
                      data.optionStats.length > 0
                    ) {
                      rows = data.optionStats.map(ot => {
                        return {
                          text: ot.OtherFlag ? "其他" : ot.OptionContent,
                          value: ot.respCount,
                        };
                      });
                    }
                    return (
                      <QuestionStatDisplayWrapper key={idx}>
                        <QuestionStatCaption
                          question={q}
                          statisticsQuestionData={data}
                          extraInfo={_getExtraInfo(q)}
                        />
                        <BarChart rows={rows} gridLines={gridLines} />
                      </QuestionStatDisplayWrapper>
                    );
                  }
                })}
            </ResultWrapper>
          </Ant.Tabs.TabPane>
        </Ant.Tabs>
      </StatWrapper>
    </>
  );
};

const StatWrapper = styled.div`
  width: 100%;

  .ant-tabs-tabpane-active {
    padding: 48px 36px;
    overflow-x: scroll;
  }

  .card {
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
    background-color: white;
  }

  .stat-tabs {
    overflow: visible;

    .ant-tabs-bar {
      box-shadow: 2px 2px 4px -1px rgba(0, 0, 0, 0.12);
      height: 56px;
      margin-bottom: 0;
      position: sticky;
      top: 0;
      z-index: 1000;

      .ant-tabs-extra-content {
        line-height: 56px;
        padding-right: 40px;

        .ant-select {
          color: ${Color.Purple};
          font-size: 16px;
        }
      }
    }

    .ant-tabs-nav {
      height: 56px;
    }

    .ant-tabs-tab {
      height: 56px;
      line-height: 32px;
    }

    .ant-tabs-nav-scroll {
      padding-left: 72px;
    }
  }
`;

const OptionSetReplyWrapper = styled.div`
  width: 1128px;
  margin: 0 auto;
  color: ${Color.GreyBlack_65};
`;

const TotalAmountCard = styled.div`
  width: 264px;
  height: 119px;
  padding: 16px 16px 28px;
  margin-right: 24px;

  .title {
    margin-bottom: 13px;
    font-size: 16px;
  }

  .amount-total {
    font-size: 36px;
    display: inline-block;
    width: 190px;
    height: 38px;
    line-height: 38px;
    margin-right: 4px;
  }

  .amount-per-day {
    font-size: 14px;
    font-weight: 500;
    color: ${Color.GreyBlack_85};
    display: inline-block;
    width: 38px;
    height: 22px;
    border-radius: 4px;
    background-color: #ffe599;
    text-align: center;
  }
`;

const DataDownloadCard = styled.div`
  width: 552px;
  height: 119ox;
  padding: 16px 16px 12px;

  .title {
    font-size: 16px;
  }

  .index {
    display: inline-block;
    width: 19px;
    flex: 1 0 auto;
  }
`;

const ReplyCard = styled.div`
  padding: 24px 36px 36px;

  .title {
    font-size: 20px;
    color: ${Color.GreyBlack_85};
    font-weight: 500;
    margin-bottom: 24px;
  }

  .question-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;

    .question {
      width: 516px;
    }

    .question-title {
      font-size: 16px;
      font-weight: 500;
      color: ${Color.GreyBlack_65};
      margin-bottom: 8px;
    }

    .question-input {
      .ant-input {
        height: 40px;
        font-size: 16px;
      }
    }
  }

  .reply-wrapper {
    .title {
      font-size: 16px;
      font-weight: 500;
      color: ${Color.GreyBlack_65};
      margin-bottom: 8px;
    }

    .table {
      .ant-table-cell {
        color: ${Color.GreyBlack_65};
        font-weight: normal;
        height: 46px;
        padding: 0 8px;
      }
    }
  }
`;

const ResultWrapper = styled.div`
  width: 1128px;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  background-color: white;
  margin: 0 auto;
`;

const QuestionStatDisplayWrapper = styled.div`
  padding: 40px 96px 40px 32px;
  border-bottom: 1px solid ${Color.PurpleLight_2};
`;

const Card = styled.div`
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  background-color: ${Color.GreyWhite};
  width: 264px;
  height: 137px;
  padding: 16px;
  border-radius: 5px;
  margin-bottom: 39px;
  margin-right: 24px;
`;

const OutsideWrapper = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  min-height: 100%;
  overflow: auto;
  padding-top: 32px;
  padding-bottom: 32px;

  & > .stat-content {
    width: 1128px;
    margin: 0 auto;
  }
`;

const Wrapper = styled.div`
  min-height: 500px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);

  & > .tab-wrapper {
    display: flex;
    padding: 24px 32px;
    padding-bottom: 0px;
    & > .tab {
      font-size: ${FontSize.title}px;
      color: ${Color.GreyBlack_85};
      border-bottom: 2px solid transparent;
      padding: 5px 0px;
      margin-right: 80px;
      cursor: pointer;
    }

    & > .tab.active {
      color: ${Color.Purple};
      border-bottom: 2px solid ${Color.Purple};
    }
  }
`;

const Loading = styled.div`
  width: 100%;
  height: 100%;
  display: ${props => (props.loading ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 999999;
  background-color: rgba(255, 255, 255, 0.6);
`;

export default SurveyStat;
